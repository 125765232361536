$primary-color: #3c68c3;
$secondary-color: #2a304e;
$third-color: #232840;
$main-container-bg: #f7f9fc !important;
$sidebar-color: #d4d6db;
$text-color: #7a7d7e;
$disabled-text: #c0c1c2;
$disabled-bg: white;
$text-secondary-color: #2a304e;
$font-regular: 'HelveticaNeue-Regular';
$font-medium: 'HelveticaNeue-Medium';
$font-bold: 'HelveticaNeue-Bold';
$font-light: 'HelveticaNeue-Light';
$base-color: #29304d;
$base-font-size: 16px;
$box-background: #fff;
$white-box-shadow: 0 0px 4px #00000029;
$box-padding: 20px;
$box-margin: 20px;
$border-radius: 6px;
$text-left: left;
$site-transition: all 0.3s;
$display-flex: flex;
$top-spacing: 35px;
$top-20: 20px;
$text-right: right;
$grey-0:#F1F2F5;
$grey-1:#ECECED;
$grey-2: #CCCCCC;
$grey-3: #C0C1C2;
$grey-4: #626467 ;
$white:#FCFCFC;
$black:#1C1C1C;
$purple:#9C27B0;
$orange-1:#FF9800;
$orange-2:#E28413;
$red:#FF0000;
$light-red:#FDF4F5;
$material-green:#4CAF50;
$pale-green:#9CCC65;
$red-tint:#FFEFE8;
$magenta:#D81B60;
$magenta-tint:#FFEFF6;
$yellow:#F4CD00;
$brown:#C95400;