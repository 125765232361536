@import '../../assets/scss/variable.scss';

.downloadButton{
    margin-left: auto;
    border:1px solid $primary-color;
    border-radius: 5px;
    padding:10px 20px 10px 20px;
    color: $primary-color;
    img{
        filter: brightness(0) saturate(100%) invert(40%) sepia(15%) saturate(2466%) hue-rotate(182deg) brightness(96%) contrast(94%);
    }
    cursor:pointer;
}