@import "../../assets/scss/variable.scss";

/*/////////////////monthSelector Start///////////////////*/
.monthSelector{        
  cursor: pointer;
  background: #F3F3F3;
  border-radius: .25rem;
  display: flex;
  overflow-X: auto;
  // scrollbar-width:none;
   scrollbar-gutter: 'sta';
   padding:10px;
}

.monthSelector::-webkit-scrollbar {
  height: 7px;
}

.monthSelector::-webkit-scrollbar-thumb {
  background:#A6A6A6;
  border-radius: 20px;
}

.monthSelector::-webkit-scrollbar-track {
  border:2px solid #A6A6A6;
  border-radius: 20px;
}
/*/////////////////monthSelector end///////////////////*/

.month{
    padding: 4px 10px 4px 10px;
    font-size: 12px;
    color: #5D6069;
    border-bottom: 1px solid #F3F3F3;
    width: 100%;
    text-align:center;
}

.activeMonth{
    border: 0.5px solid $secondary-color;
    border-radius: 5px;
}


.chartLoading{
    background: linear-gradient(-45deg, #ffffff 40%, #cbdcff 50%, #eee 70%); // #fafafa
    background-size: 300%;
    background-position-x : 100%;
    animation: shimmer .75s infinite linear;
    pointer-events: none;
    height: 50vh;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes shimmer {
   to {
      background-position-x: 0%
   }
}