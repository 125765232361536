@import './variable.scss';

.form-shadow {
  border: 1px solid #dddddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fdfdfe;
}
.search-feeder .fa {
  float: right;
  margin-right: 7px;
  margin-top: -31px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  color: #b9b9b9;
}

.form-substation-shadow {
  border: 1px solid #dddddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fdfdfe;
}

.main-feeder-div .feeder-title {
  background-color: #f8f8fa;
}

.mr-submit-btn .btn {
  padding: 0.435rem 3.1rem;
  float: right;
  margin-top: 1rem;
  background-color: #3c68c3;
}
.nav-vertical {
  a {
    border-left: 5px transparent solid;
  }

   a:hover{
     background-color: rgba(255, 255, 255,0.1);
    }
    
    a[disabled]{
    // background-color: $primary-color;
    pointer-events: none;
    opacity: 0.2;
  }

  a.active {
    background-color: #3c68c3;
    color: #fff !important;
    border-left: 5px #fff solid;
  }
  span {
    margin-left: 15px;
  }
 
}

.dashboard-negative-loss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  span.title {
    font-size: 13px;
  }
  ul {
    display: block !important;
    background-color: transparent !important;
    li {
      border-bottom: none !important;
      span {
        display: inline-block;
        width: 50%;
        font-size: 16px;
      }
    }
  }
}