@import '../../../../assets/scss/variable.scss';



.fileStatusContainer{
    margin: 25px 0px;

}

.loadMoreBtnContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
