@import '../../../../assets/scss/variable.scss';


.header{
    display: flex;
    align-items: center;
    img{
        width: 26px;
        height: 26px;
    }
}

.heading{
    font: 500 18px 'Roboto', sans-serif;
}

.fileStatusContainer{
    margin: 30px 0px;
}


.refresh-btn{
    background-color: $white;
    color: $primary-color;
    border-radius: 18px;
    border: 1.46px solid $primary-color;
    font-size: 14px;
    text-align: center;
    box-shadow: 0.98px 0.98px 16px 0px rgba(0,0,0,0.2);
    i{
      margin-left: 10px;
      color: $primary-color;
      font-size: 14px;
      font-weight: 500;
        
    }
}

.refresh-btn:hover{
    background-color: $white !important;
    text-decoration: none;
    color: $primary-color;
    box-shadow: 5px 5px 16px 1px rgba(0,0,0,0.3);
    border-color: $primary-color;

}

.loadMoreBtnContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}