@import '../../../assets/scss/variable.scss';

.header{
    display: flex;
    align-items: center;

    img{
        width: 26px;
        height: 26px;
        cursor: pointer;
    }
}

.heading{
    font: 500 18px 'Roboto', sans-serif;
}

.filterBtn {
    margin:0px 15px 0px auto;

    display: $display-flex;
    align-items: center;
    background-color: $grey-1;
    border: 1px solid $grey-2;
    color: #29304d;

    img {
        margin-right: 7px;
        width: 15px;
        height: 10px;
      }
 }

.filterPillsContainer{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    margin-top:15px;
    }
.filterPills{
    display: flex;
    align-items: center;
    background: $white;
    border:1px solid $grey-3;
    border-radius: 18px;
    box-shadow: 1px 1px 16.5px 0 rgba(0,0,0,0.1);
    padding:5px 15px;
    font: 500 12px 'Roboto',sans-serif;
    margin-right: 10px;
    .closeIcon{
        cursor: pointer;
        margin-left:5px;
        width: 12px;
        height: 12px;
        filter: brightness(0) saturate(100%) invert(40%) sepia(3%) saturate(505%) hue-rotate(177deg) brightness(94%) contrast(91%);

    }
}

