@import '../../../assets/scss/variable.scss';


.root{
display: flex;
align-items: center;
}

.icon{
height: 20px;
width:20px;
margin-right: 10px;
}

.heading{
font: 400 14px 'Roboto', sans-serif;
color: $grey-4;
}

.content{
font: 400 14px 'Roboto', sans-serif;
color: $black;
}

.infoIcon{
 height: 17px;
 width:17px;
 margin-left: 10px;
 cursor: pointer;
}