@import '../../../assets/scss/variable.scss';

.container{
   background: $primary-color;
   width: 150px;
   border-radius: 18px;
   padding: 3px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
}

.text{
    text-align: center;
    color: $white;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Roboto', 'sans-serif'
}