@import '../../../assets/scss/variable.scss';

.resend-btn {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: $secondary-color;
  cursor: pointer;
  background-color: white;
  padding: 0px;
  width: 40%;
  border: 0px;
}
.resend-btn:disabled {
  color: $disabled-text;
  background-color: white;
}
