@import '../../../assets/scss/variable.scss';

.root{
    margin:15px 10px 15px 10px;
}

.heading{
    font:500 20px "Roboto",sans-serif;
    color:$grey-4;
    margin-bottom: 15px;
}

.resultStatusContainer{
    margin-bottom: 20px;
    border:1px solid $grey-2;
    background: $white;
    border-radius: 6px;
    padding:15px 25px 15px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ineligibleContainer{
    margin-bottom: 20px;
    border:1px solid $red;
    background: $light-red;
    border-radius: 6px;
    padding:15px;
    // display: flex;
    align-items: center;
    justify-content: space-between;
    .ineligibleHeading{
        font:700 18px 'Roboto',sans-serif;
        color:$red;
        margin-bottom:15px;
    }
    .ineligibleSubHeading{
        font:500 16px "Roboto",sans-serif;
        color:$black;
        margin:20px 0px;
    }
    .ineligibleText{
        font:400 14px "Roboto",sans-serif;
        color:$grey-4;
        margin-bottom:20px;
    }
}


:export{
    // awaitingProcessing:$purple;
    primaryColor :$primary-color;
    // processed:$material-green;
    // validating:$orange-1;
    // validationFailed:$red;
    // ineligible:$secondary-color;
    // processingFailed:$text-color;
}

