@import 'variable';
@import 'media';
@import 'mixins';

@font-face {
  font-family: 'HelveticaNeue-Regular';
  src: url(../fonts/HelveticaNeue-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url(../fonts/HelveticaNeue-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url(../fonts/HelveticaNeue-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url(../fonts/HelveticaNeue-Light.otf) format('opentype');
}



html, body, #root, #root>div {// scroll-fix (prev change - remove this section)
  height: 100%
}


html {
  // max-width: 1920px;
  // margin: 0 auto; // handle design for ultra wide screen
  //   background-color: lightgrey;
}

body {
  padding: 0;
  font-size: $base-font-size;
  font-family: $font-regular;
  h1 {
    font-size: 22px;
    color: $base-color;
    font-family: $font-medium;
    margin-bottom: 35px;
  }

  h3 {
    font-size: 18px;
    color: $base-color;
    font-family: $font-medium;
  }
}

.container {
  max-width: 100%;
  padding: 30px;
}

.font-medium {
  font-family: $font-medium !important;
}

.font-bold {
  font-family: $font-bold !important;
}

.font-light-300 {
  font-family: $font-light !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.mt-35 {
  margin-top: $top-spacing;
}

.mt-20 {
  margin-top: $top-20;
}

.mr-0 {
  margin-right: 0 !important;
}

.text-right {
  text-align: $text-right;
}

.pr-20 {
  padding-right: 20px !important;
}

.add-new-btn {
  background-color: #3c68c3;
  border-color: #3c68c3;
}

.confirm-btn {
  background-color: #3c68c3;
  border-color: #3c68c3;
  color: #fff;
  max-width: 122px;
  width: 100%;
  font-family: $font-medium;
  padding: 7px 10px;
  margin-left: 10px;
}

.edit-btn {
  background-color: transparent;
  border-color: #3c68c3;
  color: #3c68c3;
  padding: 7px 10px;
  max-width: 122px;
  width: 100%;
  font-family: $font-medium;
}

button.btn.ghost-btn {
  background-color: transparent !important;
  color: #3c68c3;
  max-width: inherit;
  width: auto;
  outline: 0;
  box-shadow: inherit;
  -moz-box-shadow: inherit;
  -webkit-box-shadow: inherit;
}
button.btn.ghost-btn img {
  margin-right: 6px;
  position: relative;
  top: -2px;
}

.nav-inner-wrapper ul li a {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

::-moz-placeholder {
  color: #373a3c !important;
  font-size: $base-font-size;
  opacity: 0.8 !important;
  font-family: $font-regular;
}

::-webkit-input-placeholder {
  color: #373a3c !important;
  opacity: 0.8 !important;
  font-size: $base-font-size;
  font-family: $font-regular;
}

:-ms-input-placeholder {
  color: #373a3c !important;
  opacity: 0.8 !important;
  font-size: $base-font-size;
  font-family: $font-regular;
}

::placeholder {
  color: #373a3c !important;
  opacity: 0.8 !important;
  font-size: $base-font-size;
  font-family: $font-regular;
}

input.form-control,
select,
textarea.form-control,
input,
textarea {
  padding: 7px 10px;
  font-family: $font-regular;
  color: #373a3c;
  font-weight: normal;
  outline: 0;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  opacity: 0.8;
}

input[type='radio'] {
  padding: 0 !important;
}

input.search {
  background: url('/assets/img/search-tc-icon.svg') no-repeat right 8px center;
  background-size: 23px auto;
  max-width: 280px;
  width: 100%;
  padding-right: 40px;
}

.top-feeder-search {
  background: url('/assets/img/search-tc-icon.svg') no-repeat right 8px center;
  background-size: 23px auto;
  padding-right: 40px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #ddd;

  .css-yk16xz-control,
  .css-1pahdxg-control {
    border: none !important;
  }

  .css-g1d714-ValueContainer {
    border: none;
  }
}

.top-feeder-search img {
  display: none;
}

.top-feeder-search .css-1okebmr-indicatorSeparator,
.top-feeder-search .css-tlfecz-indicatorContainer,
.top-feeder-search .css-1gtu0rj-indicatorContainer {
  display: none !important;
}

input.search-field,
.search-field {
  background: url('/assets/img/search-tc-icon.svg') no-repeat right 8px center;
  background-size: 23px auto;
  padding-right: 36px !important;
}

input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
  box-shadow: inherit !important;
  -moz-box-shadow: inherit !important;
  -webkit-box-shadow: inherit !important;
}

table {
  border: none;
  margin-top: 10px;
  margin-bottom: 0 !important;

  thead {
    th {
      background-color: #fff !important;
    }
  }

  th,
  td {
    font-family: $font-medium;
    font-size: $base-font-size;
    color: #29304d;
    text-align: $text-left;
    white-space: nowrap;
  }

  td {
    border-bottom: 1px solid #dddddd;
  }

  tr:nth-of-type(2n + 1) {
    background-color: rgba(241, 242, 245, 0.5);
    -webkit-transition: $site-transition;
    -o-transition: $site-transition;
    transition: $site-transition;
  }

  tr:nth-of-type(2n) {
    background-color: #fff;
    -webkit-transition: $site-transition;
    -o-transition: $site-transition;
    transition: $site-transition;
  }

  tr:hover {
    background-color: #f1f2f5;
  }

  th {
    font-weight: normal;
    border-bottom: none;
    padding: 10px 8px !important;
  }

  td {
    font-family: $font-regular;
    padding: 10px 8px !important;
  }
}



.search-form {
  box-shadow: $white-box-shadow;
  -moz-box-shadow: $white-box-shadow;
  -webkit-box-shadow: $white-box-shadow;
  background-color: $box-background;
  padding: $box-padding;
  margin-bottom: 20px;
  border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  -webkit-border-radius: $border-radius;
  .btn.btn-primary {
    margin-top: -1px;
    max-width: 122px;
    padding: 8px 15px;
    text-align: center;
    background-color: #c0c1c2;
    color: #fff;
    font-family: $font-medium;
    border: none;
    width: 100%;
    display: block;
  }
}

.list-view-box {
  box-shadow: $white-box-shadow;
  -moz-box-shadow: $white-box-shadow;
  -webkit-box-shadow: $white-box-shadow;
  background-color: $box-background;
  margin-bottom: $box-margin;
  padding-top: 0;
  border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  -webkit-border-radius: $border-radius;

  .search-heading {
    padding: 15px;

    .form-control {
      box-shadow: none;
      margin-right: 89px;
    }

    .export-filter {
      display: $display-flex;
      justify-content: flex-end;

      span {
        position: relative;
        top: 1px;
        left: -3px;
      }
    }

    .export-btn {
      line-height: 2.3;
      color: #29304d;
      text-decoration: none;
      cursor: pointer;
      margin-right: 30px;
    }

    .export-btn img {
      line-height: 2.1;
      font-size: 18px;
    }

    .filter-btn {
      display: $display-flex;
      background-color: #ececed;
      border: 1px solid #dddddd;
      color: #29304d;

      img {
        margin-right: 7px;
        position: relative;
        top: 6px;
      }
    }
  }
}

.pagination {
  display: $display-flex;
  padding-left: 0;
  list-style: none;

  input[type='text'] {
    width: 30px;
    height: 30px;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-top: 4px;
  }

  .next-item {
    margin-right: 10px;
    a {
      background-color: transparent;
      color: #777 !important;
    }
  }

  .previous-item {
    margin-left: 15px;
    span {
      line-height: 2.4;
      font-size: 15px;
    }
  }

  .page-link.active {
    color: #3c68c3;
  }
}

.my-installation-content {
  .tc-audit-no-title {
    background: url('/assets/img/main-tab-arrow.svg') no-repeat right 10px center;
  }
}

.management-title {
  h1 {
    margin-bottom: 15px;
  }
}

.add-mr-content {
  .code-section {
    span {
      color: #797b84;
      font-family: 'HelveticaNeue-Regular';
    }
  }
}

.substation-list {
  .collapse-text[aria-expanded='true'] {
    background-color: #f8f8fa66;
  }

  .card-body {
    table {
      tr {
        background-color: #fff !important;
      }
    }
  }
}

.main-tc-table.list-view-box {
  box-shadow: inherit;
  border-radius: inherit;
  padding-top: 0 !important;
}

.filter-bar {
  .filter-title {
    font-family: 'HelveticaNeue-Bold';
  }

  .mr-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
  }

  .month-tab .tabs a {
    margin-bottom: 14px;
  }
}

.tc-audit-no-list {
  .tc-audit-no-title {
    background: none;
  }

  .number {
    color: #29304d;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
}

.audit-list-box {
  .react-datepicker-wrapper {
    input {
      border-left: 1px solid #ced4da;
    }
  }
}

.add-tc-info {
  .code-section {
    margin-bottom: 15px;
  }

  span {
    color: #797b84;
  }

  .close-mr .btn {
    display: block !important;
    width: auto !important;
  }
}

.transformer-detail-form {
  .energy-audit-view p.heading {
    font-family: $font-medium;
  }

  ul {
    li {
      list-style: none;
    }
  }
}

.dashboard-content .react-datepicker-popper {
  top: -5px !important;
  left: -110px !important;
}

.tc-number {
  padding: 3px 10px;
  border-radius: 30px;
  // max-width: 90px;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  background-color: #3c68c3;
  color: #fff;
}

.tc-number:hover {
  background-color: #000;
  color: #fff;
}

.react-datepicker__month--disabled,
.react-datepicker__year-text--disabled{
  color: #ccc !important; // Make it gray
  pointer-events: none;   // Disable clicking
  text-decoration: line-through; // Optional: Strike-through
  opacity: 0.5; 
}

.react-datepicker__month-container .react-datepicker__current-month {
  font-family: 'HelveticaNeue-Medium';
  color: #29304d;
}

.react-datepicker__month-container .react-datepicker__day-names .react-datepicker__day-name {
  color: #2c2c2c;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
}

.react-datepicker__month-container .react-datepicker__day {
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #2c2c2c;
}

.react-datepicker__month-container .react-datepicker__day.react-datepicker__day--outside-month {
  color: #e9e9e9;
}

.react-datepicker__month-container .react-datepicker__day--keyboard-selected {
  background-color: #4168b9;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: transparent;
}

.react-datepicker .react-datepicker-year-header {
  color: #29304d;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.react-datepicker .react-datepicker__month-text {
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #909293;
}

.react-datepicker .react-datepicker__month-text--keyboard-selected {
  color: #fff;
}

.select-field {
  background: url(/assets/img/yeardownarrow@2x.png) no-repeat right 5px;
  background-size: 31px auto;
  background-color: white;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  color: #373a3c;
  opacity: 0.8;
}

.css-g1d714-ValueContainer {
  padding-right: 40px !important;
}

.iconbox {
  background-color: #eff0f2;
  width: 45px;
  height: 45px;
  position: relative;
  padding: 8px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.iconbox img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.profile-content .list-view-box {
  padding: 25px 20px;

  .form-label {
    display: block;
  }

  .form-label sup.red {
    color: #ff0000;
    top: -5px;
    left: 1px;
  }

  .divider {
    border: 1px dashed #707070;
    background-color: unset;
    margin: 15px 0;
  }

  .btn-grey {
    background-color: #c0c1c2;
    max-width: 125px;
    width: 100%;
    color: #fff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-color: #c0c1c2;
  }

  .label-value {
    margin-bottom: 0;
  }
}

.search-feeder .fa {
  z-index: inherit !important;
}
