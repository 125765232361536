@import '../../../assets/scss/variable.scss';

.root{
    background-color: white;
    border: 1px solid $sidebar-color;
    // box-shadow: $white-box-shadow;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    position: relative;
}

.left-col{
    width: 40%;
}
.right-col{
    width: 60%;
    display: flex;
    justify-content: flex-end;
    .react-datepicker__tab-loop {
        .react-datepicker-popper {
            .react-datepicker {
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
                  border: unset;
                  .react-datepicker__navigation {
                    top: 22px;
                  }
                  .react-datepicker__triangle {
                    display: none;
                  }
                  .react-datepicker__month-container {
                    .react-datepicker__header {
                      background-color: unset;
                      border-bottom: unset;
                      font-size: 18px;
                      padding-bottom: 8px;
                      line-height: 2.3;
                    }
                    .react-datepicker__month {
                      .react-datepicker__month-wrapper {
                        .react-datepicker__month-text {
                          font-size: 16px;
                          border-radius: 18px;
                          padding: 7px 10px 7px 10px;
                          width: 6.2rem;
                          margin: 3px;
                          font-family: 'Roboto', sans-serif;
                          font-weight: 500;
                        }
                        .react-datepicker__month-text--keyboard-selected {
                          background-color: $primary-color;
                        }
                      }
                    }
                  }
                  .react-datepicker__year--container {
                    .react-datepicker__header {
                      background-color: unset;
                      border-bottom: unset;
                      font-size: 18px;
                    }
                    .react-datepicker__year {
                      .react-datepicker__year-wrapper {
                        justify-content: center;
                        .react-datepicker__year-text {
                          font-size: 15px;
                          border-radius: 18px;
                          font-family: 'Roboto', sans-serif;
                        }
                        .react-datepicker__year-text--keyboard-selected {
                          background-color: $primary-color !important;
                        }
                        .react-datepicker__month-text--keyboard-selected {
                          background-color: $primary-color !important;
                        }
                      }
                    }
                  }
                }
              }
    }
    .react-datepicker-wrapper input {
      background-image: url(../../../assets/img/calendar-icon.svg);
      background-repeat: no-repeat;
      background-position: right 11px center;
      display: block;
      font-size: 16px;
      font-weight: 400;
      background-color: #fff;
      border: 1.5px solid $grey-2;
      max-width: 185px;
    //   width: 250%;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      outline: none;
    }


}

.custom-btn{
    width: 35%;
    max-width: 130px;
    margin-left: 10px;
    border-color: $primary-color;
    border-radius: 4px;
    position: relative;
    
    img {
        width: 20px;
        height: 20px;
    }

    input{
    position: absolute;
    bottom:-2px;
    right: 0px;
    opacity: 0;
    }

}

.small-text{
    color: $primary-color;
    cursor: pointer;
    font-size: 16px;
    margin-top: 5px;
    img{
        width: 16px;
        height: 16px;
        margin-right: 2px;
        filter: brightness(0) saturate(100%) invert(33%) sepia(94%) saturate(748%) hue-rotate(192deg) brightness(93%) contrast(86%);
    }
    .download-icon{
        width: 18px;
        height: 18px;
        margin-left: 3px;
        filter: brightness(0) saturate(100%) invert(33%) sepia(94%) saturate(748%) hue-rotate(192deg) brightness(93%) contrast(86%);
    }
}

.heading{
   font-family: 'Roboto', sans-serif;
   font-weight: 500;
   font-size: 20px;
}