    @import '../../assets/scss/variable.scss';
    @import '../../assets/scss/constants.scss';
  



    .auditButton:hover{
      padding-right:25px
    }
    
    .file-upload-content {

      div[disabled]{
        cursor: not-allowed;
        .tc-audit-no-collapse{
          pointer-events: none;
          opacity: 0.3;
        }
      }

      .tc-audit-no-list {
        min-height: 100%;
        background-color: #fff;
        border-right: 1px solid #dddddd;
        // padding-bottom: 14rem;
        .tc-audit-no-collapse.active {
          background-color: #e5ecff;
        }
        .tc-audit-no-collapse {
          cursor: pointer;
          border-bottom: 1.6px solid #dddddd; // made it 1.6px since it is not visible in 720p display 
          position: relative;
          .collapse-text {
            display: flex;
            .tc-audit-no-title {
              width: 65%;
              p {
                width: 100%;
                // display: -webkit-box;
                // -webkit-box-orient: vertical;
                // -webkit-line-clamp: 1;
                // overflow: hidden;
              }
              
             

              .tc-no {
                font-weight: 500;
                line-height: 1.2;
                color: #4168b9;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;

              }
              .tc-no-text {
                font-size: 16px;
                line-height: 1.3;
                color: $base-color;
                font-family: 'Roboto', sans-serif;
              }
            }
             .right-arrow{
                width: 20px;
                height: 20px;
              }
            .delete-tc-audit {
              position: absolute;
              right: 25px;
              display: flex;
              .fa-angle-right {
                font-size: 21px;
              }
            }
          }
        }
      }

      
      .disabled{
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      }

      .tc
      .pagination-1 {
        float: right;
        .pagination {
          .page-item {
            .page-link {
              background-color: unset;
              color: #212529;
            }
          }
          .previous-item {
            display: flex;
            span {
              line-height: 2.4;
              font-size: 15px;
            }
            .page-link {
              background-color: unset;
            }
            .page-link.active {
              color: #3c68c3;
            }
          }
        }
      }
      .code-section {
        display: flex;
        .vertical-line {
          width: 2px;
          opacity: 1;
          height: 29px;
          background-color: #edf0f2;
        }
        .add-new-tc-text {
          a {
            color: #3c68c3;
            text-decoration: none;
          }
        }
      }
      .horizontal-line {
        background-color: #edf0f2;
        height: 2px;
        opacity: 1;
        margin: 1.3rem 0;
        margin-bottom: 7px;
        margin-top: 7px;
      }
      .add-tc-title {
        h5 {
          margin-bottom: 0;
          line-height: 1.8;
        }
      }
      .close-mr {
        justify-content: flex-end;
        display: flex;
        .btn {
          display: flex;
          background-color: #fff;
          border-color: #3c68c3;
          color: #3c68c3;
          box-shadow: none;
          width: 25%;
          img {
            margin-right: 6px;
          }
        }
      }
      .transformer-detail-form {
        box-shadow: 0 5px 15px rgba(58, 40, 40, 0.08);
        background-color: #fff;
        border-radius: 0.35rem;
        .fa-compass {
          position: absolute;
          right: 22px;
          top: 41px;
          font-size: 20px;
          color: #ced4da;
          font-weight: 100;
        }
        .divider {
          border: 1px dashed #707070;
          background-color: unset;
          margin: 1.8rem 0;
          margin-top: 0;
        }
        .tc-submit-btn {
          .btn {
            background-color: #c0c1c2;
            border-color: #c0c1c2;
            padding: 0.435rem 3.1rem;
          }
        }
      }
      .main-tc-name-form {
        .main-final-reading-div {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          background-color: #f8f8fa;
          .final-reading-title {
            background-color: #fff;
            padding: 11px 21px 11px 21px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom: 1px solid #dddddd;
            display: flex;
            p {
              flex: 1;
            }
            form {
              .form-select {
                padding: 0.275rem 1.75rem 0.275rem 0.75rem;
              }
            }
          }
          .final-reading-form {
            padding: 21px;
            .forms-input {
              .mr-save-btn {
                .btn {
                  background-color: #3c68c3;
                  border: 1px solid #3c68c3;
                  padding: 0.435rem 3.1rem;
                  margin-top: 30px;
                  box-shadow: none;
                }
              }
            }
          }
        }
        .history-section {
          border-radius: 4px;
          padding: 15px 21px 15px 21px;
          background-color: #fff;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
        }
        .installation-list {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          background-color: #fff;
          border-radius: 0.35rem;
          padding: 1rem 0rem 0rem 0rem;
          .search-heading {
            display: flex;
            .search-space {
              .form-control {
                box-shadow: none;
                margin-right: 89px;
              }
              .fa {
                position: absolute;
                top: 9px;
                right: 11px;
              }
            }
            .download-upload {
              display: flex;
              justify-content: flex-end;
              .upload-btn {
                position: absolute;
                display: flex;
                color: $base-color;
              }
              .download-btn {
                display: flex;
                color: $base-color;
                background-color: unset;
                border: none;
                box-shadow: none;
              }
              .installation-btn {
                display: flex;
                color: $base-color;
                background-color: unset;
                box-shadow: none;
                .fa {
                  line-height: 1.5;
                  margin-right: 7px;
                }
              }
            }
          }
          .installing-list {
            border-top: 1px solid #dddddd;
            .table {
              thead {
                tr {
                  th {
                    border-bottom: 1px solid #dddddd;
                    clear: both;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                }
              }
              tbody {
                tr {
                  td {
                    a {
                      text-decoration: none;
                      color: #212529;
                      .fa-eye {
                        margin-right: 4px;
                      }
                      .fa-pencil {
                        margin-right: 4px;
                      }
                    }
                    .active {
                      background-color: $primary-color;
                      padding: 3px 7px 3px 7px;
                      border-radius: 10px;
                      font-size: 13px;
                      color: #fff;
                    }
                    .inactive {
                      background-color: $secondary-color;
                      padding: 3px 7px 3px 7px;
                      border-radius: 10px;
                      font-size: 13px;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
        .reading-form {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          background-color: #fff;
          border-radius: 0.35rem;
          .mr-submit-btn {
            .btn {
              background-color: #c0c1c2;
              border-color: #c0c1c2;
              padding: 0.435rem 2.1rem;
            }
          }
        }
      }
      .pagination {
        .next-item {
          a {
            background-color: unset;
            color: #212529;
          }
        }
        .previous-item {
          display: flex;
          span {
            line-height: 2.4;
            font-size: 15px;
          }
          a {
            background-color: unset;
          }
          .page-link.active {
            color: #3c68c3;
          }
        }
        .page-item {
          .page-link {
            color: #212529;
          }
        }
      }
    }

    .sidebar-container {
       width: 20%;
       height: calc(100vh - #{$headerHeight});
       overflow: auto;
    }

    .file-upload-container {
       height: calc(100vh - #{$headerHeight});
       overflow: auto;
       width: 80%;
       padding: 0px;
    }

    .auditButtonContainer{
    position: fixed;
    bottom:0;
    right:0;
    // background: pink;
    display: flex;
    // align-items: flex-end;
    // padding:20px;
    flex:1;
    margin-bottom:25px;
}