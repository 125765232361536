@import '../../../assets/scss/variable.scss';

.retry-btn {
  background-color: $primary-color;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.heading-n {
  font-size: 24px;
  color: $secondary-color;
  // color: pink;
  font-weight: bold;
  margin-bottom: 10px;
}
.body {
  font-size: 16px;
  color: $secondary-color;
  margin-bottom: 20px;
}

.container-n {
  text-align: center;
  padding: 20px;
  font-family: sans-serif;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f2f2f2;
}

.container-n img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}
