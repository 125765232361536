@import '../../assets/scss/variable.scss';

.filterContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}

.filterHeading{
    font:400 18px 'Roboto',sans-serif;
}

.filterPill{
    font:400 18px 'Roboto',sans-serif;
    border:1px solid $grey-2;
    border-radius: 35px;
    padding: 10px 15px 10px 15px;
    margin:0px 5px 0px 5px;
    cursor:pointer;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.filterLoading{
    background: linear-gradient(-45deg, #ffffff 40%, #cbdcff 50%, #eee 70%); // #fafafa
    background-size: 300%;
    background-position-x : 100%;
    animation: shimmer .75s infinite linear;
    pointer-events: none;
}

@keyframes shimmer {
   to {
      background-position-x: 0%
   }
}

.selectedFilter{
    background: $white;
    color:$primary-color;
    border:1px solid $primary-color;
    .closeIcon{
        filter: brightness(0) saturate(100%) invert(35%) sepia(13%) saturate(2768%) hue-rotate(182deg) brightness(107%) contrast(93%);
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }
}

.downloadButton{
    margin-left: auto;
    border:1px solid $primary-color;
    border-radius: 5px;
    padding:10px 20px 10px 20px;
    color: $primary-color;
    img{
        filter: brightness(0) saturate(100%) invert(40%) sepia(15%) saturate(2466%) hue-rotate(182deg) brightness(96%) contrast(94%);
    }
    cursor:pointer;
}

