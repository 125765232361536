@import '../../../assets/scss/variable.scss';

.button{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color:$grey-1;
    padding:10px 30px;
    border-radius: 25px;
    font: 400 16px 'Roboto',sans-serif;
}

.arrowDown{
    width:15px;
    height:15px;
    filter: brightness(0) saturate(100%);
    transform:rotate(270deg);
    margin-left: 8px;
}

.spinner{
    margin-left: 10px;

}