@import 'variable';
@import 'constants';
@import 'media';
@import 'mixins';

@font-face {
  font-family: 'HelveticaNeue-Regular';
  src: url(../fonts/HelveticaNeue-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url(../fonts/HelveticaNeue-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url(../fonts/HelveticaNeue-Bold.otf) format('opentype');
}

html {
  position: relative;
  min-height: 100%;
  body {
    .display-n {
      display: none;
    }
    color: $base-color;
    .font-20 {
      font-size: 20px;
    }

    .form-label {
      margin-bottom: 4px;
    }

    .icon-width-height {
      height: 22px;
      width: 22px;
      margin-right: 12px;
    }
    .icon-width-height-24 {
      height: 24px;
      width: 24px;
    }
    .roboto {
      font-family: 'Roboto', sans-serif;
    }
    .font-regular-400 {
      font-weight: 400;
    }
    .font-medium-500 {
      font-weight: 500;
    }
    .font-light-300 {
      font-weight: 300;
    }
    .cp {
      cursor: pointer;
    }
    .custom-active {
      background-color: $primary-color !important;
      color: #fff !important;
    }
    .active-card-body {
      color: #3c68c3;
      border-color: #3c68c3 !important;
    }
    .activated {
      background-color: $primary-color !important;
    }
    position: static;
    min-height: 100%;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-family: 'HelveticaNeue-Regular';
   
    .main-container {
      height : calc(100% - #{$headerHeight}); // scroll-fix (prev change - remove this line)
      overflow: auto; // scroll-fix (prev change - remove this line) 
      border-right: 1px solid $disabled-text;
      position: static;
      .nav-vertical {
        z-index: 11;
        position: static;
        float: left;
        width: 243px;
        background-color: $secondary-color;
        .nav-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          .nav-inner-wrapper {
            ul {
              padding-left: 0;
              list-style: none;
              margin-bottom: 0;
              li {
                position: relative;
                margin: 0;
                -webkit-transition: all 0.2s ease-in-out;
                -moz-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
                padding-top: 8px;
                padding-bottom: 8px;
                a {
                  text-decoration: none;
                  color: $sidebar-color;
                  display: flex;
                  padding: 12px 0px 12px 19px;
                  p {
                    font-family: 'Roboto', sans-serif;
                  }
                  h5 {
                    margin-bottom: 0;
                  }
                }
              }
              li:nth-child(4) {
                a {
                  position: relative;
                  .fa {
                    position: absolute;
                    right: 16px;
                    top: 27%;
                    font-size: 22px;
                  }
                }
                ul {
                  background-color: #2d3b64;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  li {
                    padding-bottom: 0;
                    padding-top: 0;
                    .submenu-menu {
                      color: #fff;
                    }
                    a {
                      padding: 10px 0px 10px 61px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .small-sidebar {
        .nav-wrapper {
          .nav-inner-wrapper {
            ul {
              li a {
                padding: 12px 0px 12px 29px;
              }
            }
          }
        }
      }
      .nav-vertical::before {
        z-index: -1;
        content: ' ';
        line-height: 0;
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        width: inherit;
        background-color: inherit;
        border-width: inherit;
        border-style: inherit;
        border-color: inherit;
      }
      .dashboard-content {
        @include important-text;
        margin-left: 0;
        .top-tabs {
          p {
            font-size: 14px;
            color: #686a6c;
            margin-bottom: 3px !important;
          }
          h5 {
            font-size: 18px;
            font-weight: 700;
            color: #29304d;
          }
        }
        .card {
          border: none;
          box-shadow: $white-box-shadow;
          -moz-box-shadow: $white-box-shadow;
          -webkit-box-shadow: $white-box-shadow;
          border-radius: 0.35rem;
          .card-body {
            .top-heading {
              display: flex;
              .card-subtitle {
                flex: 1;
                font-size: 18px;
                font-weight: 500;
                color: #29304d;
                line-height: 2.1;
              }
            }
            ul {
              list-style: none;
              padding-left: 0;
              display: flex;
              background: #f3f3f3;
              margin-bottom: 0;
              border-radius: 0.25rem;
              li {
                padding: 4px 10px 4px 10px;
                font-size: 12px;
                color: #5d6069;
                border-bottom: 1px solid #f3f3f3;
                width: 100%;
                // text-align:center;
              }
              .year-active {
                color: $base-color;
                border-color: $base-color;
              }
            }
            .contain {
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
            .year-list {
              position: absolute;
              bottom: 10px;
              left: 14px;
              right: 14px;
            }
          }
          .card-body.charts {
            padding-bottom: 65px;
          }
        }
        .dashboard-charts {
          .react-datepicker-wrapper {
            .react-datepicker__input-container {
              input {
                background-image: url(../img/year-down-arrow.svg);
                background-size: 22px auto;
                border-left: 1px solid #ced4da;
                max-width: 85px;
                width: 100%;
                line-height: 1;
                font-size: 12px;
                background-position: right center;
              }
            }
          }
        }
      }
      .management-content {
        @include important-text;
        margin-left: 0;
        .comman-head {
          display: flex;
          .management-title {
            flex: 1;
            h5 {
              margin-bottom: 0;
              line-height: 1.8;
            }
          }
          .add-new-mr {
            text-align: -webkit-right;
            .btn {
              box-shadow: none;
              img {
                line-height: 1.5;
                margin-right: 10px;
              }
              p {
                font-size: 14px;
                line-height: 1.7;
              }
            }
          }
        }
        .horizontal-line {
          background-color: #707070;
          height: 1px;
          opacity: 0.2;
          margin: 1.3rem 0;
          margin-bottom: 7px;
        }
        .code-section {
          display: flex;
          margin: 20px 0px 15px 0px ;
          align-items: center;
          .vertical-line {
            width: 2px;
            opacity: 1;
            height: 29px;
            background-color: #dcdde0;
            margin-top: 0;
          }

          span {
            font-size: 20px;
            font-family: 'HelveticaNeue-Light';
            color: $base-color;

            b {
              font-family: 'HelveticaNeue-Medium';
            }
          }
        }
        .main-management-table {
          background-color: #fff;
          padding-top: 15px;
          .search-heading {
            justify-content: flex-end;
            display: flex;
            .fa {
              position: absolute;
              top: 10px;
              right: 15px;
            }
            .form-control {
              box-shadow: none;
            }
          }
          .table {
            border-top: 2px solid #eeeeee;
            tbody {
              tr {
                td {
                  a {
                    text-decoration: none;
                    color: #212529;
                    .fa-pencil {
                      margin-right: 18px;
                    }
                  }
                }
              }
            }
            thead {
              tr {
                th {
                  border-bottom: 2px solid #eeeeee;
                }
              }
            }

            td {
              border-bottom: 2px solid #eeeeee;
            }
          }
        }
        nav {
          float: right;
          .pagination {
            .page-link {
              color: #212529;
            }
            .page-link.active {
              color: $primary-color;
            }
            .next-item {
              .page-link {
                background-color: unset;
              }
            }
            .previous-item {
              display: flex;
              span {
                line-height: 2.4;
                font-size: 15px;
              }
              .page-link {
                background-color: unset;
              }
            }
          }
        }
      }
      .tc-audit-content {
        .tc-audit-no-list {
          background-color: #fff;
          border-right: 1px solid #dddddd;
          border-bottom: 1px solid #dddddd;
          padding-bottom: 14rem;
          .add-new-tc-no {
            background: none;
            border-bottom: 1px solid #dddddd;
            .collapse-text {
              line-height: 1;
              display: flex;
              .tc-audit-no-title {
                .tc-no-text {
                  line-height: 1.3;
                  width: 100%;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                }
              }
              .delete-tc-audit {
                line-height: 1.3;
                a {
                  color: #212529;
                  .fa {
                    font-size: 14px;
                    font-weight: 100;
                  }
                }
              }
            }
          }
          .tc-audit-no-collapse {
            border-bottom: 1px solid #dddddd;
            position: relative;
            .collapse-text {
              display: flex;
              img {
                max-height: 26px;
              }
              .tc-audit-no-title {
                p {
                  width: 100%;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                }
                .tc-no {
                  font-weight: 700;
                  line-height: 1.2;
                }
                .tc-no-text {
                  $font-light: 'HelveticaNeue-Light';
                  color: #212121;
                  font-size: 16px;
                  line-height: 1.3;
                }
              }
              .delete-tc-audit {
                position: absolute;
                right: 25px;
                display: flex;
                .fa-angle-down {
                  display: none;
                  font-size: 21px;
                  margin-left: 6px;
                }
                .fa-angle-right {
                  display: none;
                  font-size: 21px;
                  margin-left: 6px;
                }
                .trash-tc {
                  font-size: 19px;
                  text-decoration: none;
                  color: #212529;
                  line-height: 1.1;
                }
              }
            }
            .collapse-text[aria-expanded='false'] {
              .delete-tc-audit .fa-angle-down {
                display: block !important;
              }
            }
            .collapse-text[aria-expanded='true'] {
              .delete-tc-audit .fa-angle-right {
                display: block !important;
              }
            }
            .collapse-text[aria-expanded='true'] {
              .delete-tc-audit .trash-tc {
                display: block !important;
              }
            }
          }
          .collapse-detail {
            .card {
              background-color: #f8f8fa;
              .tc-installation-loss {
                display: flex;
                span {
                  width: 100%;
                  background-color: #e8f1ff;
                  border: 1px solid #bfdaff;
                  padding: 10px 2px;
                  border-radius: 4px;
                  -moz-border-radius: 4px;
                  -webkit-border-radius: 4px;
                  color: #5d6069;
                  font-family: 'Roboto', sans-serif;
                  font-size: 16px;
                }
              }
              ul {
                list-style: none;
                padding-left: 0;
                li {
                  color: #212121;
                  font-size: 15px;
                  margin-bottom: 8px;
                  b {
                    font-weight: bold;
                    font-size: 15px;
                    font-family: 'Roboto', sans-serif !important;
                  }
                }
              }
            }
          }
        }
        .upload-download-rr-tagging {
          padding: 15px;
          background-color: #fff;
          border-right: 1px solid #dddddd;
          border-bottom: 1px solid #dddddd;
          a {
            display: flex;
            width: 100%;
          }
          .upload-btn {
            background-color: #f5f4ff;
            border: 1px solid #3c68c3;
            color: #3c68c3;
            box-shadow: none;
          }
          .download-btn {
            background-color: #3c68c3;
            border: 1px solid #3c68c3;
            box-shadow: none;
          }
        }
        .top-transformer-tab {
          .custom-card-tc-1 {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            background-color: #f5f4ff;
            border: 1px solid #3b1fed;
            .card-body {
              display: flex;
              color: #3b1fed;
              hr {
                opacity: 1;
                width: 1px;
                margin: 4px 19px 0px 0px;
                height: 40px;
              }
              h4 {
                line-height: 1.98;
              }
            }
          }
          .custom-card-tc-2 {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            background-color: #fffafc;
            border: 1px solid #d81b60;
            .card-body {
              display: flex;
              color: #d81b60;
              hr {
                opacity: 1;
                width: 1px;
                margin: 4px 19px 0px 0px;
                height: 40px;
              }
              h4 {
                line-height: 1.98;
              }
            }
          }
          .custom-card-tc-3 {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            background-color: #f6fff0;
            border: 1px solid #50ad16;
            .card-body {
              display: flex;
              color: #50ad16;
              hr {
                opacity: 1;
                width: 1px;
                margin: 4px 19px 0px 0px;
                height: 40px;
              }
              h4 {
                line-height: 1.98;
              }
            }
          }
          .custom-card-tc-4 {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            background-color: #ffffff;
            border: 1px solid #3c68c3;
            .card-body {
              display: flex;
              color: #3c68c3;
              hr {
                opacity: 1;
                width: 1px;
                margin: 4px 19px 0px 0px;
                height: 40px;
              }
              h4 {
                text-align: center;
                line-height: 1.2;
                p {
                  font-size: 14px;
                }
              }
              .card-text {
                line-height: 2.8;
              }
            }
          }
          .custom-card-tc-5 {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            background-color: #ffffff;
            border: none;
            .card-body {
              display: flex;
              color: #686a6c;
              hr {
                opacity: 1;
                width: 1px;
                margin: 4px 19px 0px 0px;
                height: 40px;
              }
              h4 {
                text-align: center;
                line-height: 1.2;
                color: $base-color;
                p {
                  font-size: 14px;
                }
              }
              .card-text {
                line-height: 2.8;
              }
            }
          }
          .custom-card-tc-6 {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            border: none;
            background-color: #ffffff;
            .card-body {
              display: flex;
              color: #686a6c;
              hr {
                opacity: 1;
                width: 1px;
                margin: 4px 19px 0px 0px;
                height: 40px;
              }
              h4 {
                text-align: center;
                line-height: 1.2;
                color: $base-color;
                p {
                  font-size: 14px;
                }
              }
              .card-text {
                line-height: 2.8;
              }
            }
          }
          .custom-card-tc-7 {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            border: none;
            background-color: #ffffff;
            .card-body {
              display: flex;
              color: #686a6c;
              hr {
                opacity: 1;
                width: 1px;
                margin: 4px 19px 0px 0px;
                height: 40px;
              }
              h4 {
                text-align: center;
                line-height: 1.2;
                color: $base-color;
                p {
                  font-size: 14px;
                }
              }
              .card-text {
                line-height: 2.8;
              }
            }
          }
          .custom-card-tc-8 {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            border: none;
            background-color: #ffffff;
            .card-body {
              display: flex;
              color: #686a6c;
              hr {
                opacity: 1;
                width: 1px;
                margin: 4px 19px 0px 0px;
                height: 40px;
              }
              h4 {
                text-align: center;
                line-height: 1.2;
                color: $base-color;
                p {
                  font-size: 14px;
                }
              }
              .card-text {
                line-height: 2.8;
              }
            }
          }
          .custom-card-tc-9 {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            border: none;
            background-color: #ffffff;
            .card-body {
              display: flex;
              color: #686a6c;
              hr {
                opacity: 1;
                width: 1px;
                margin: 4px 19px 0px 0px;
                height: 40px;
              }
              h4 {
                text-align: center;
                line-height: 1.2;
                color: $base-color;
                p {
                  font-size: 14px;
                }
              }
              .card-text {
                line-height: 2.8;
              }
            }
          }
          .main-tc-table {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            background-color: #fff;
            border-radius: 0.35rem;
            padding: 1rem 0rem 0rem 0rem;
            .search-heading {
              display: flex;
              .search-box {
                flex: 1;
                input {
                  box-shadow: none;
                }
                .fa {
                  position: absolute;
                  top: 10px;
                  right: 15px;
                }
              }
              .download-result {
                text-align: -webkit-right;
                a {
                  display: flex;
                  background-color: #3c68c3;
                  border-color: #3c68c3;
                  box-shadow: none;
                  .fa {
                    line-height: 1.5;
                    margin-right: 15px;
                  }
                  p {
                    font-size: 14px;
                    line-height: 1.7;
                  }
                }
              }
            }
            .download-result {
              .btn {
                display: inline-flex;
                background-color: #3c68c3;
                border-color: #3c68c3;
                box-shadow: none;
              }
            }
            .table {
              border-top: 1px solid #dddddd;
              thead tr th {
                border-bottom: 1px solid #dddddd;
              }
              tbody tr td a {
                text-decoration: none;
                color: #212529;
              }
            }
          }
          .upload-download-tc-detail {
            display: flex;
            justify-content: flex-end;
            a {
              display: flex;
            }
            .download-btn {
              background-color: #3c68c3;
              border-color: #3c68c3;
              box-shadow: none;
            }
            .upload-btn {
              background-color: #fff;
              border-color: #3c68c3;
              box-shadow: none;
              color: #3c68c3;
            }
          }
        }
        .pagination-1 {
          float: right;
          .pagination {
            .page-item {
              .page-link {
                background-color: unset;
                color: #212529;
              }
            }
            .previous-item {
              display: flex;
              span {
                line-height: 2.4;
                font-size: 15px;
              }
              .page-link {
                background-color: unset;
              }
              .page-link.active {
                color: #3c68c3;
              }
            }
          }
        }
        .code-section {
          display: flex;
          .vertical-line {
            width: 2px;
            opacity: 1;
            height: 29px;
            background-color: #edf0f2;
          }
          .add-new-tc-text {
            a {
              color: #3c68c3;
              text-decoration: none;
            }
          }
        }
        .horizontal-line {
          background-color: rgba(112, 112, 112, 0.2);
          height: 1px;
          opacity: 1;
          margin: 1.3rem 0;
          margin-bottom: 7px;
          margin-top: 7px;
        }
        .add-tc-title {
          h5 {
            margin-bottom: 0;
            line-height: 1.8;
          }
        }
        .close-mr {
          justify-content: flex-end;
          display: flex;
          .btn {
            display: flex;
            background-color: #fff;
            border-color: #3c68c3;
            color: #3c68c3;
            box-shadow: none;
            width: 25%;
            img {
              margin-right: 6px;
            }
          }
        }
        .transformer-detail-form {
          box-shadow: $white-box-shadow;
          -moz-box-shadow: $white-box-shadow;
          -webkit-box-shadow: $white-box-shadow;
          border-radius: 4px;
          -moz-border-radius: 4px;
          -webkit-border-radius: 4px;
          border-radius: 4px;
          .fa-compass {
            position: absolute;
            right: 22px;
            top: 36px;
            font-size: 20px;
            color: #ced4da;
            font-weight: 100;
          }
          .divider {
            border: 1px dashed #707070;
            background-color: unset;
            margin: 1.8rem 0;
            margin-top: 0;
          }
          .tc-submit-btn {
            .btn {
              background-color: #c0c1c2;
              border-color: #c0c1c2;
              padding: 0.435rem 3.1rem;
            }
          }
        }
        .main-tc-name-form {
          .main-final-reading-div {
            box-shadow: $white-box-shadow;
            -moz-box-shadow: $white-box-shadow;
            -webkit-box-shadow: $white-box-shadow;
            border-radius: 4px;
            -moz-border-radius: 4px;
            -webkit-border-radius: 4px;
            background-color: #f8f8fa;
            .final-reading-title {
              background-color: #fff;
              font-family: $font-medium !important;
              padding: 6px 6px 6px 21px;
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
              border-bottom: 1px solid #dddddd;
              display: flex;
              p {
                flex: 1;
              }
              form {
                .form-select {
                }
              }
            }
            .final-reading-form {
              padding: 21px;
              .forms-input {
                .mr-save-btn {
                  .btn {
                    background-color: #3c68c3;
                    border: 1px solid #3c68c3;
                    padding: 0.435rem 3.1rem;
                    margin-top: 27px;
                    box-shadow: none;
                  }
                }
              }
            }
          }
          .history-section {
            border-radius: 4px;
            padding: 15px 21px 15px 21px;
            background-color: #fff;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          }
          .installation-list {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
            background-color: #fff;
            border-radius: 0.35rem;
            padding: 1rem 0rem 0rem 0rem;
            .search-heading {
              display: flex;
              .search-space {
                .form-control {
                  box-shadow: none;
                  margin-right: 89px;
                }
                .fa {
                  position: absolute;
                  top: 9px;
                  right: 11px;
                }
              }
              .download-upload {
                display: flex;
                justify-content: flex-end;
                .upload-btn {
                  position: absolute;
                  display: flex;
                  color: $base-color;
                }
                .download-btn {
                  display: flex;
                  color: $base-color;
                  background-color: unset;
                  border: none;
                  box-shadow: none;
                }
                .installation-btn {
                  display: flex;
                  color: $base-color;
                  background-color: unset;
                  border: none;
                  box-shadow: none;
                  .fa {
                    line-height: 1.5;
                    margin-right: 7px;
                  }
                }
              }
            }
            .installing-list {
              border-top: 1px solid #dddddd;
              .table {
                thead {
                  tr {
                    th {
                      border-bottom: 1px solid #dddddd;
                    }
                  }
                }
                tbody {
                  tr {
                    td {
                      a {
                        text-decoration: none;
                        color: #212529;
                        .fa-eye {
                          margin-right: 4px;
                        }
                        .fa-pencil {
                          margin-right: 4px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .pagination {
          .next-item {
            a {
              background-color: unset;
              color: #212529;
            }
          }
          .previous-item {
            display: flex;
            span {
              line-height: 2.4;
              font-size: 15px;
            }
            a {
              background-color: unset;
            }
            .page-link.active {
              color: #3c68c3;
            }
          }
          .page-item {
            .page-link {
              color: #212529;
            }
          }
        }
      }
      .add-mr-content {
        @include important-text;
        margin-left: 0;
        .code-section {
          display: flex;
          margin-bottom: 15px;
          .vertical-line {
            width: 2px;
            opacity: 1;
            height: 29px;
            background-color: #edf0f2;
          }
          .add-new-mr-text {
            a {
              color: $primary-color;
              text-decoration: none;
            }
          }
        }
        .horizontal-line {
          background-color: rgba(112, 112, 112, 0.2);
          height: 1px;
          opacity: 1;
          margin: 17px 0;
          margin-bottom: 7px;
          margin-top: 7px;
        }
        .management-title {
          h5 {
            margin-bottom: 0;
            line-height: 1.8;
          }
        }
        .close-mr {
          justify-content: flex-end;
          display: flex;
          .btn {
            display: flex;
            background-color: #fff;
            border-color: $primary-color;
            color: $primary-color;
            box-shadow: none;
            border-radius: 4px;
            -moz-border-radius: 4px;
            -webkit-border-radius: 4px;
            img {
              margin-right: 6px;
            }
          }
        }
        .main-management-form {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          background-color: #fff;
          border-radius: 0.35rem;
          .forms-input {
            .mr-submit-btn {
              .btn {
                padding: 0.435rem 2.1rem;
              }
              .btn-grey {
                background-color: #c0c1c2;
                border-color: #c0c1c2;
              }
            }
            .inner-add-part {
              border: 1px solid #dddddd;
              padding: 15px;
              border-radius: 4px;
              -moz-border-radius: 4px;
              -webkit-border-radius: 4px;
              background-color: #fdfdfe;
            }
          }
        }
      }
      .add-substation {
        @include important-text;
        margin-left: 0;
        .code-section {
          display: flex;
          margin-bottom: 15px;

          span {
            color: #797b84;
            font-family: 'HelveticaNeue-Regular';
          }

          .vertical-line {
            width: 2px;
            opacity: 1;
            height: 29px;
            background-color: #edf0f2;
          }
          .add-new-substation-text {
            a {
              color: $primary-color;
              text-decoration: none;
            }
          }
        }
        .horizontal-line {
          background-color: rgba(112, 112, 112, 0.2);
          height: 1px;
          opacity: 1;
          margin: 1.3rem 0;
          margin-bottom: 7px;
          margin-top: 7px;
        }
        .substation-title {
          h5 {
            margin-bottom: 0;
            line-height: 1.8;
          }
        }
        .close-mr {
          justify-content: flex-end;
          display: flex;
          .btn {
            display: flex;
            background-color: transparent;
            border-color: $primary-color;
            color: $primary-color;
            box-shadow: none;
            img {
              margin-right: 6px;
            }
          }
        }
        .main-substation-form {
          box-shadow: $white-box-shadow;
          -moz-box-shadow: $white-box-shadow;
          -webkit-box-shadow: $white-box-shadow;
          background-color: #fff;
          border-radius: $border-radius;
          .forms-input {
            .mr-submit-btn {
              .btn {
                background-color: #c0c1c2;
                border-color: #c0c1c2;
                padding: 0.435rem 2.1rem;
              }
            }
          }
          .divider {
            border: 1px dashed #707070;
            background-color: unset;
            margin: 1.8rem 0;
          }
          .main-feeder-div {
            border: 1px solid #dddddd;
            border-radius: 4px;
            background-color: #fdfdfe;
            .feeder-title {
              background-color: #f8f8fa;
              padding: 11px 21px 11px 21px;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              border-bottom: 1px solid #dddddd;
            }
            .feeder-form {
              padding: 21px;
              .mr-save-btn {
                .btn {
                  background-color: #c0c1c2;
                  border-color: #c0c1c2;
                  padding: 0.435rem 3.1rem;
                  margin-top: 30px;
                }
              }
            }
          }
          .feeder-section {
            border: 1px solid #dddddd;
            border-radius: 4px;
            padding: 15px 21px 15px 21px;
            .delete-btn {
              .fa {
                color: #c0c1c2;
                font-size: 21px;
                cursor: pointer;
              }
            }
            .feeder-number {
              p {
                b {
                  font-weight: 500;
                }
              }
            }
            .feeder-name {
              p {
                b {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
      .common-scroll-content {
        margin-left: 245px;
      }
      .common-toggle-content {
        margin-left: 84px !important;
      }

      .disconnection-content {
        @include important-text;
        margin-left: 0;
        .comman-head {
          display: flex;
          .management-title {
            flex: 1;
            h5 {
              margin-bottom: 0;
              line-height: 1.8;
            }
          }
        }
        .horizontal-line {
          background-color: #edf0f2;
          height: 2px;
          opacity: 1;
          margin: 1.3rem 0;
          margin-bottom: 7px;
        }
        .code-section {
          display: flex;
          .vertical-line {
            width: 2px;
            opacity: 1;
            height: 29px;
            background-color: #edf0f2;
          }
        }
        .main-discussion-table {
          .search-heading {
            .search-space {
              .fa {
                position: absolute;
                top: 9px;
                right: 11px;
              }
              .form-control {
              }
            }
            .export-filter {
              display: flex;
              justify-content: flex-end;
              .export-btn {
                display: flex;
                line-height: 2.3;
                color: $base-color;
                text-decoration: none;
                cursor: pointer;
                .fa {
                  line-height: 2.1;
                  font-size: 18px;
                }
              }
            }
          }
          .table {
            border-top: 1px solid #dddddd;
            tbody {
              tr {
                td {
                  a {
                    text-decoration: none;
                    color: #212529;
                    .fa-pencil {
                      margin-right: 18px;
                    }
                  }
                }
              }
              tr:nth-child(odd) {
                background-color: #f1f2f5;
              }
            }
            thead {
              tr {
                th {
                  border-bottom: 1px solid #dddddd;
                  clear: both;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        nav {
          float: right;
          .pagination {
            .page-link {
              color: #212529;
            }
            .page-link.active {
              color: $primary-color;
            }
            .next-item {
              .page-link {
                background-color: unset;
              }
            }
            .previous-item {
              display: flex;
              span {
                line-height: 2.4;
                font-size: 15px;
              }
              .page-link {
                background-color: unset;
              }
            }
          }
        }
        .main-discconection-form {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          background-color: #fff;
          border-radius: 0.35rem;
          .select2 {
            .select2-selection {
              padding: 0.375rem 0.75rem;
              border: 1px solid #ced4da;
              .select2-search {
                line-height: 1;
                .select2-search__field {
                  margin-top: 0;
                  margin-left: 0;
                }
              }
            }
          }
          .mr-submit-btn {
            .btn {
              background-color: #c0c1c2;
              border-color: #c0c1c2;
              padding: 0.435rem 2.1rem;
            }
          }
        }
      }
    }
    .main-container::before {
      content: ' ';
      line-height: 0;
      z-index: -2;
      position: absolute;
      display: block;
      width: 100%;
      max-width: inherit;
      top: 0;
      bottom: 0;
      background-color: $main-container-bg;
    }
    // installation start
    .my-installation-content {
      .tc-audit-no-list {
        cursor: pointer;
        background-color: #fff;
        border-right: 1px solid #dddddd;
        padding-bottom: 14rem;
        .tc-audit-no-collapse.active {
          background-color: #F1F2F5;
        }
        .tc-audit-no-collapse {
          border-bottom: 1.6px solid #dddddd; // made it 1.6px since it is not visible in 720p display 
          position: relative;
          .collapse-text {
            display: flex;
            .tc-audit-no-title {
              p {
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              .tc-no {
                font-weight: 700;
                line-height: 1.2;
                color: #4168b9;
                font-family: 'Roboto', sans-serif;
              }
              .tc-no-text {
                font-size: 16px;
                line-height: 1.3;
                color: $base-color;
                font-family: 'Roboto', sans-serif;
              }
            }
            .delete-tc-audit {
              position: absolute;
              right: 25px;
              display: flex;
              .fa-angle-right {
                font-size: 21px;
              }
            }
          }
        }
      }
      .pagination-1 {
        float: right;
        .pagination {
          .page-item {
            .page-link {
              background-color: unset;
              color: #212529;
            }
          }
          .previous-item {
            display: flex;
            span {
              line-height: 2.4;
              font-size: 15px;
            }
            .page-link {
              background-color: unset;
            }
            .page-link.active {
              color: #3c68c3;
            }
          }
        }
      }
      .code-section {
        display: flex;
        .vertical-line {
          width: 2px;
          opacity: 1;
          height: 29px;
          background-color: #edf0f2;
        }
        .add-new-tc-text {
          a {
            color: #3c68c3;
            text-decoration: none;
          }
        }
      }
      .horizontal-line {
        background-color: #edf0f2;
        height: 2px;
        opacity: 1;
        margin: 1.3rem 0;
        margin-bottom: 7px;
        margin-top: 7px;
      }
      .add-tc-title {
        h5 {
          margin-bottom: 0;
          line-height: 1.8;
        }
      }
      .close-mr {
        justify-content: flex-end;
        display: flex;
        .btn {
          display: flex;
          background-color: #fff;
          border-color: #3c68c3;
          color: #3c68c3;
          box-shadow: none;
          width: 25%;
          img {
            margin-right: 6px;
          }
        }
      }
      .transformer-detail-form {
        box-shadow: 0 5px 15px rgba(58, 40, 40, 0.08);
        background-color: #fff;
        border-radius: 0.35rem;
        .fa-compass {
          position: absolute;
          right: 22px;
          top: 41px;
          font-size: 20px;
          color: #ced4da;
          font-weight: 100;
        }
        .divider {
          border: 1px dashed #707070;
          background-color: unset;
          margin: 1.8rem 0;
          margin-top: 0;
        }
        .tc-submit-btn {
          .btn {
            background-color: #c0c1c2;
            border-color: #c0c1c2;
            padding: 0.435rem 3.1rem;
          }
        }
      }
      .main-tc-name-form {
        .main-final-reading-div {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          background-color: #f8f8fa;
          .final-reading-title {
            background-color: #fff;
            padding: 11px 21px 11px 21px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom: 1px solid #dddddd;
            display: flex;
            p {
              flex: 1;
            }
            form {
              .form-select {
                padding: 0.275rem 1.75rem 0.275rem 0.75rem;
              }
            }
          }
          .final-reading-form {
            padding: 21px;
            .forms-input {
              .mr-save-btn {
                .btn {
                  background-color: #3c68c3;
                  border: 1px solid #3c68c3;
                  padding: 0.435rem 3.1rem;
                  margin-top: 30px;
                  box-shadow: none;
                }
              }
            }
          }
        }
        .history-section {
          border-radius: 4px;
          padding: 15px 21px 15px 21px;
          background-color: #fff;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
        }
        .installation-list {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          background-color: #fff;
          border-radius: 0.35rem;
          padding: 1rem 0rem 0rem 0rem;
          .search-heading {
            display: flex;
            .search-space {
              .form-control {
                box-shadow: none;
                margin-right: 89px;
              }
              .fa {
                position: absolute;
                top: 9px;
                right: 11px;
              }
            }
            .download-upload {
              display: flex;
              justify-content: flex-end;
              .upload-btn {
                position: absolute;
                display: flex;
                color: $base-color;
              }
              .download-btn {
                display: flex;
                color: $base-color;
                background-color: unset;
                border: none;
                box-shadow: none;
              }
              .installation-btn {
                display: flex;
                color: $base-color;
                background-color: unset;
                box-shadow: none;
                .fa {
                  line-height: 1.5;
                  margin-right: 7px;
                }
              }
            }
          }
          .installing-list {
            border-top: 1px solid #dddddd;
            .table {
              thead {
                tr {
                  th {
                    border-bottom: 1px solid #dddddd;
                    clear: both;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                }
              }
              tbody {
                tr {
                  td {
                    a {
                      text-decoration: none;
                      color: #212529;
                      .fa-eye {
                        margin-right: 4px;
                      }
                      .fa-pencil {
                        margin-right: 4px;
                      }
                    }
                    .active {
                      background-color: $primary-color;
                      padding: 3px 7px 3px 7px;
                      border-radius: 10px;
                      font-size: 13px;
                      color: #fff;
                    }
                    .inactive {
                      background-color: $secondary-color;
                      padding: 3px 7px 3px 7px;
                      border-radius: 10px;
                      font-size: 13px;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
        .reading-form {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          background-color: #fff;
          border-radius: 0.35rem;
          .mr-submit-btn {
            .btn {
              background-color: #c0c1c2;
              border-color: #c0c1c2;
              padding: 0.435rem 2.1rem;
            }
          }
        }
      }
      .pagination {
        .next-item {
          a {
            background-color: unset;
            color: #212529;
          }
        }
        .previous-item {
          display: flex;
          span {
            line-height: 2.4;
            font-size: 15px;
          }
          a {
            background-color: unset;
          }
          .page-link.active {
            color: #3c68c3;
          }
        }
        .page-item {
          .page-link {
            color: #212529;
          }
        }
      }
    }
    // installation end
    .export-excel-modal {
      .modal-footer {
        .btn-primary {
          background-color: $primary-color;
          border-color: $primary-color;
          box-shadow: none;
        }
        .btn-secondary {
          background-color: unset;
          border-color: #dddddd;
          color: #212529;
          box-shadow: none;
        }
      }
    }
    //  login css start
    .login-form-detail {
      height: 100%;
      padding: 0rem 9.3rem 0rem 8.9rem;
      .welcome-title {
        h4 {
          font-size: 30px;
        }
      }
      .forms-input {
        position: relative;
        .fa {
          position: absolute;
          bottom: 15px;
          font-size: 22px;
          left: 18px;
          color: #838796;
        }
        input {
          padding: 0.775rem 2.9rem;
          box-shadow: none;
        }
        .input-min-pass {
          padding: 0.775rem 1rem;
          box-shadow: none;
        }
      }
      .remember-forgotten {
        display: flex;
        color: #818a91;
        .forgot {
          a {
            text-decoration: none;
            color: #818a91;
          }
        }
      }
      .btn {
        width: 100%;
        padding: 0.8rem 0.75rem;
        background-color: #c0c1c2;
        border-color: #c0c1c2;
        box-shadow: none;
      }
      .btn:hover {
        background-color: #c0c1c2;
        border-color: #c0c1c2;
      }
    }
    .tc-audit-content {
      .main-tc-name-form {
        .main-final-reading-div {
          box-shadow: $white-box-shadow;
          -moz-box-shadow: $white-box-shadow;
          -webkit-box-shadow: $white-box-shadow;
          border-radius: 4px;
          -moz-border-radius: 4px;
          -webkit-border-radius: 4px;
          background-color: #f8f8fa;
          .final-reading-title {
            background-color: #fff;
            padding: 11px 21px 11px 21px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom: 1px solid #dddddd;
            display: flex;
            p {
              flex: 1;
              font-size: 18px;
              font-weight: 500;
              line-height: 2;
            }
            form {
              .form-select {
                padding: 0.275rem 1.75rem 0.275rem 0.75rem;
                border-radius: 4px;
              }
            }
          }
          .final-reading-form {
            padding: 21px;
            .forms-input {
              .mr-save-btn {
                .btn {
                  background-color: #3c68c3;
                  border: 1px solid #3c68c3;
                  padding: 0.435rem 3.1rem;
                  margin-top: 30px;
                  box-shadow: none;
                }
              }
            }
          }
        }
        .history-section {
          border-radius: 4px;
          padding: 0;
          background-color: #fff;
          box-shadow: $white-box-shadow;
          -moz-box-shadow: $white-box-shadow;
          -webkit-box-shadow: $white-box-shadow;
          border-radius: 4px;
          -moz-border-radius: 4px;
          -webkit-border-radius: 4px;
          .history-collapse {
            .collapse-text {
              color: $base-color;
              text-decoration: none;
              display: flex;
              line-height: 1.5;
              .tc-audit-history-collpase-title {
                margin-right: auto;
                p {
                  font-size: 18px;
                  font-family: $font-medium !important;
                  line-height: 2;
                }
              }
              .select-icon {
                display: flex;
                .react-datepicker-wrapper {
                  .react-datepicker__input-container {
                    input {
                      background-image: url('../img/down-filled-triangular-arrow.svg');
                      border-left: 1px solid #ced4da;
                    }
                  }
                }
                .fa {
                  line-height: 2.4;
                  margin-left: 12px;
                }
              }
            }
          }
          .collapse-detail {
            .audit-history-list {
              border-top: 1px solid #dddddd;
              .table {
                thead {
                  tr {
                    th {
                      border-bottom: 1px solid #dddddd;
                    }
                  }
                }
                tbody {
                  tr {
                    th {
                      clear: both;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                    td {
                      clear: both;
                      overflow: hidden;
                      white-space: nowrap;
                      a {
                        color: #212529;
                        text-decoration: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .count-installation-list {
          box-shadow: $white-box-shadow;
          -moz-box-shadow: $white-box-shadow;
          -webkit-box-shadow: $white-box-shadow;
          border-radius: 4px;
          -moz-border-radius: 4px;
          -webkit-border-radius: 4px;
          background-color: #fff;
          padding: 1rem 0rem 0rem 0rem;
          .search-heading {
            display: flex;
            .search-space {
              .form-control {
                box-shadow: none;
                margin-right: 89px;
              }
              .fa {
                position: absolute;
                top: 9px;
                right: 11px;
              }
            }
            .download-upload {
              display: flex;
              justify-content: flex-end;
              .upload-btn {
                position: absolute;
                display: flex;
                color: $base-color;
              }

              .download-btn {
                display: flex;
                color: $base-color;
                background-color: unset;
                border: none;
                box-shadow: none;
              }
              .installation-btn {
                display: flex;
                color: $base-color;
                background-color: unset;
                border: none;
                box-shadow: none;
                .fa {
                  line-height: 1.5;
                  margin-right: 7px;
                }
              }
            }
          }
          .installing-list {
            box-shadow: inherit;
            .table {
              thead {
                tr {
                  th {
                    border-bottom: 1px solid #dddddd;
                    clear: both;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                }
              }
              tbody {
                tr:nth-child(odd) {
                  background-color: #f1f2f5;
                }
                tr {
                  td {
                    clear: both;
                    overflow: hidden;
                    white-space: nowrap;
                    a {
                      text-decoration: none;
                      color: #212529;
                      .fa-eye {
                        margin-right: 4px;
                      }
                      .fa-pencil {
                        margin-right: 4px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // unaudited css
        .unaudited-count-installation-list {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
          background-color: #fff;
          border-radius: 0.35rem;
          padding: 1rem 0rem 0rem 0rem;
          .search-heading {
            display: flex;
            .search-space {
              .form-control {
                box-shadow: none;
                margin-right: 89px;
              }
              .fa {
                position: absolute;
                top: 9px;
                right: 11px;
              }
            }
            .download-upload {
              display: flex;
              justify-content: flex-end;
              .upload-btn {
                position: absolute;
                display: flex;
                color: $base-color;
              }
              .download-btn {
                display: flex;
                color: $base-color;
                background-color: unset;
                border: none;
                box-shadow: none;
              }
              .installation-btn {
                display: flex;
                color: $base-color;
                background-color: unset;
                border: none;
                box-shadow: none;
                .fa {
                  line-height: 1.5;
                  margin-right: 7px;
                }
              }
            }
          }
          .installing-list {
            border-top: 1px solid #dddddd;
            .table {
              thead {
                tr {
                  th {
                    border-bottom: 1px solid #dddddd;
                    clear: both;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                }
              }
              tbody {
                tr:nth-child(odd) {
                  background-color: #f1f2f5;
                }
                tr {
                  td {
                    clear: both;
                    overflow: hidden;
                    white-space: nowrap;
                    a {
                      text-decoration: none;
                      color: #212529;
                      .fa-eye {
                        margin-right: 4px;
                      }
                      .fa-pencil {
                        margin-right: 6px;
                      }
                      .fa-trash-o {
                        margin-right: 6px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .pagination {
        .page-item {
          .page-link.active {
            color: #3c68c3;
          }
          .page-link {
            background-color: unset;
            color: #212529;
          }
        }
        .previous-item {
          display: flex;
          span {
            line-height: 2.4;
            font-size: 15px;
          }
        }
      }
      .comman-head {
        display: flex;
        .tc-name-title {
          flex: 1;
          h5 {
            margin-bottom: 0;
            line-height: 1.8;
          }
        }
        .back-btn {
          .btn {
            display: flex;
            background-color: unset;
            border-color: $primary-color;
            color: $primary-color;
            box-shadow: none;
            .fa {
              line-height: 1.5;
              margin-right: 15px;
            }
            p {
              font-size: 14px;
              line-height: 1.7;
            }
          }
        }
      }
      .consumption-point {
        .horizontal-line {
          background-color: #edf0f2;
          height: 2px;
          opacity: 1;
          margin: 1.1rem 0;
          margin-bottom: 7px;
        }
        .consumption-load-section {
          display: flex;
          span {
            font-size: 19px;
            .fa {
              margin-left: 3px;
              color: $text-color;
            }
            b {
              font-size: 25px;
              font-family: $font-medium !important;
            }
          }
        }
        .vertical-line {
          width: 2px;
          opacity: 1;
          height: 29px;
          background-color: #edf0f2;
        }
      }
      .tc-audit-no-list {
        background-color: #fff;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 14rem;
        .add-new-tc-no {
          border-bottom: 1px solid #dddddd;
          background: none;
          .collapse-text {
            line-height: 1;
            display: flex;
            .tc-audit-no-title {
              .tc-no-text {
                line-height: 1.3;
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
            }
            .delete-tc-audit {
              line-height: 1.3;
              a {
                color: #212529;
                .fa {
                  font-size: 14px;
                  font-weight: 100;
                }
              }
            }
          }
          .search-collpase-text {
            position: relative;
            .form-control {
              border: none;
              border-radius: 0;
              box-shadow: none;
              padding-left: 10px;
            }
            img {
              margin-left: 25px;
            }
          }
        }
        .tc-audit-no-collapse {
          border-bottom: 1px solid #dddddd;
          position: relative;
          .collapse-text {
            display: flex;
            img {
              max-height: 26px;
            }
            .tc-audit-no-title {
              background: none;
              p {
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              .tc-no {
                font-weight: 700;
                line-height: 1.2;
              }
              .tc-no-text {
                font-weight: 300;
                font-size: 15px;
                line-height: 1.3;
              }
            }
            .delete-tc-audit {
              position: absolute;
              right: 25px;
              display: flex;
              .fa-angle-down {
                display: none;
                font-size: 21px;
                margin-left: 6px;
              }
              .fa-angle-right {
                display: none;
                font-size: 21px;
                margin-left: 6px;
              }
              .trash-tc {
                font-size: 19px;
                text-decoration: none;
                color: #212529;
                line-height: 1.1;
              }
            }
          }
          .collapse-text[aria-expanded='false'] {
            .delete-tc-audit .fa-angle-down {
              display: block !important;
            }
          }
          .collapse-text[aria-expanded='true'] {
            .delete-tc-audit .fa-angle-right {
              display: block !important;
            }
          }
          .collapse-text[aria-expanded='true'] {
            .delete-tc-audit .trash-tc {
              display: block !important;
            }
          }
        }
        .collapse-detail {
          .card {
            background-color: #f8f8fa;
            .tc-installation-loss {
              span {
                background-color: #e8f1ff;
                border: 1px solid #bfdaff;
                padding: 0.6rem;
                color: #5d6069;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
              }
            }
            ul {
              list-style: none;
              padding-left: 0;
              li {
                font-size: 15px;
                margin-bottom: 8px;
                b {
                  font-weight: bold;
                  font-size: 15px;
                  font-family: 'Roboto', sans-serif !important;
                }
              }
            }
          }
        }
      }
      .upload-download-rr-tagging {
        padding: 15px;
        background-color: #fff;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        a {
          display: flex;
          width: 100%;
        }
        .upload-btn {
          background-color: #f5f4ff;
          border: 1px solid #3c68c3;
          color: #3c68c3;
          box-shadow: none;
        }
        .download-btn {
          background-color: #3c68c3;
          border: 1px solid #3c68c3;
          box-shadow: none;
        }
      }
    }

    // add tc audit css
    .add-tc-info {
      .add-tc-title {
        h5 {
          margin-bottom: 0;
          line-height: 1.8;
        }
      }
      .close-mr {
        justify-content: flex-end;
        display: flex;
        .btn {
          display: flex;
          background-color: unset !important;
          border-color: $primary-color;
          color: $primary-color;
          box-shadow: none;
          img {
            margin-right: 6px;
          }
        }
      }
      .code-section {
        display: flex;
        .vertical-line {
          width: 2px;
          opacity: 1;
          height: 29px;
          background-color: #edf0f2;
        }
        .add-new-tc-text a {
          color: #3c68c3;
          text-decoration: none;
        }
      }
      .horizontal-line {
        background-color: #edf0f2;
        height: 2px;
        opacity: 1;
        margin: 1.3rem 0;
        margin-bottom: 7px;
        margin-top: 5px;
      }
      .transformer-detail-form {
        box-shadow: 0 5px 15px rgba(58, 40, 40, 0.08);
        background-color: #fff;
        border-radius: 0.35rem;
        .fa-compass {
          position: absolute;
          right: 22px;
          top: 41px;
          font-size: 20px;
          color: #ced4da;
          font-weight: 100;
        }
        .divider {
          border: 1px dashed #707070;
          background-color: unset;
          margin: 1.8rem 0;
          margin-top: 0;
        }
        .tc-submit-btn {
          .btn {
            background-color: #c0c1c2;
            border-color: #c0c1c2;
            padding: 0.435rem 3.1rem;
          }
        }
        .reading-radio-btn {
          line-height: 2.3;
          .form-check {
            position: relative;
            display: inline-block;
            word-wrap: break-word;
            overflow: hidden;
            max-height: 3.6em;
            line-height: 1.5em;
            text-align: justify;
            vertical-align: middle;
          }
        }
        .energy-audit-view {
          ul {
            list-style: none;
            .heading {
              font-weight: 600;
            }
          }
        }
        .tc-edit-confirm-btn {
          a {
            background-color: #fff;
            border-color: $primary-color;
            padding: 0.435rem 2.6rem;
            color: $primary-color;
            margin-right: 6px;
          }
          button {
            background-color: $primary-color;
            border-color: $primary-color;
            padding: 0.435rem 2.6rem;
          }
        }
      }
      .tc-audit-no-list {
        background-color: #fff;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 14rem;
        .add-new-tc-no {
          background: none;
          border-bottom: 1px solid #dddddd;
          .collapse-text {
            line-height: 1;
            display: flex;
            .tc-audit-no-title {
              .tc-no-text {
                line-height: 1.3;
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
            }
            .delete-tc-audit {
              line-height: 1.3;
              a {
                color: #212529;
                .fa {
                  font-size: 14px;
                  font-weight: 100;
                }
              }
            }
          }
        }
        .tc-audit-no-collapse {
          border-bottom: 1px solid #dddddd;
          position: relative;
          .collapse-text {
            display: flex;
            position: relative;
            img {
              max-height: 26px;
            }
            .tc-audit-no-title {
              background: none;

              p {
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              .tc-no {
                font-weight: 700;
                line-height: 1.2;
              }
              .tc-no-text {
                font-weight: 300;
                font-size: 15px;
                line-height: 1.3;
              }
            }
            .delete-tc-audit {
              position: absolute;
              right: 25px;
              display: flex;
              .fa-angle-down {
                display: none;
                font-size: 21px;
                margin-left: 6px;
              }
              .fa-angle-right {
                display: none;
                font-size: 21px;
                margin-left: 6px;
              }
              .trash-tc {
                display: none;
                font-size: 19px;
                text-decoration: none;
                color: #212529;
                line-height: 1.1;
              }
            }
          }
          .collapse-text[aria-expanded='false'] {
            .delete-tc-audit .fa-angle-down {
              display: block !important;
            }
          }
          .collapse-text[aria-expanded='true'] {
            .delete-tc-audit .fa-angle-right {
              display: block !important;
            }
          }
          .collapse-text[aria-expanded='true'] {
            .delete-tc-audit .trash-tc {
              display: block !important;
            }
          }
        }
        .collapse-detail {
          .card {
            background-color: #f8f8fa;
            .tc-installation-loss {
              span {
                background-color: #e8f1ff;
                border: 1px solid #bfdaff;
                padding: 0.6rem;
                color: #5d6069;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
              }
            }
            ul {
              list-style: none;
              padding-left: 0;
              li {
                font-size: 15px;
                color: #212121;
                margin-bottom: 8px;
                b {
                  font-weight: bold;
                  font-size: 15px;
                  font-family: 'Roboto', sans-serif !important;
                }
              }
            }
          }
        }
        .add-new-tc-no {
          background: none;
          .search-collpase-text {
            input {
              border: none;
              border-radius: 0;
              box-shadow: none;
            }
            .fa {
              position: absolute;
              top: 10px;
              right: 23px;
            }
          }
        }
      }
      .tc-edit-confirm-btn {
        .edit-btn {
          background-color: #fff !important;
          color: $primary-color;
          box-shadow: none;
        }
        .confirm-btn {
          background-color: $primary-color;
          border-color: $primary-color;
          box-shadow: none;
        }
      }
    }
    .react-datepicker-wrapper input {
      background-image: url(../img/calendar-icon.svg);
      background-repeat: no-repeat;
      background-position: right 11px center;
      display: block;
      font-size: 16px;
      font-weight: 400;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-left: none;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      outline: none;
    }
    .custom-final-reading {
      .final-reading-title {
        background-color: #f8f8fa !important;
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            input {
              background-image: url('../img/down-filled-triangular-arrow.svg');
              border-left: 1px solid #ced4da;
              background-position: 95% 12px;
              background-size: 17px auto;
            }
          }
        }
        .react-datepicker__tab-loop {
          .react-datepicker {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
            border: unset;
            .react-datepicker__navigation {
              top: 22px;
            }
            .react-datepicker__month-container {
              .react-datepicker__header {
                background-color: unset;
                border-bottom: unset;
                font-size: 18px;
                padding-bottom: 8px;
                line-height: 2.3;
              }
              .react-datepicker__month {
                .react-datepicker__month-wrapper {
                  .react-datepicker__month-text {
                    font-size: 16px;
                    border-radius: 18px;
                    padding: 7px 3px 7px 3px;
                    width: 5.9rem;
                    margin: 3px;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
      .final-reading-form {
        background-color: #fff;
      }
    }
    .tc-number {
      td {
        span {
          background-color: $primary-color;
          padding: 3px 10px 3px 10px;
          color: #fff;
          font-size: 14px;
          border-radius: 12px;
        }
      }
    }
    .custom-row {
      .react-datepicker-wrapper {
        width: 100%;
        input {
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          border: 1px solid #ced4da;
          appearance: none;
          border-radius: 0.25rem;
        }
      }
    }
    .select-remark {
      position: relative;
      img {
        position: absolute;
        right: 25px;
        top: 65%;
      }
    }
    .reading-day-input {
      .react-datepicker-wrapper {
        width: 100%;
        input {
          display: block;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          border: 1px solid #ced4da;
          -webkit-appearance: none;
          appearance: none;
          border-radius: 0.25rem;
        }
      }
      .react-datepicker__tab-loop {
        .react-datepicker {
          .react-datepicker__navigation {
            top: 14px;
          }
          .react-datepicker__month-container {
            .react-datepicker__month {
              .react-datepicker__week {
                .react-datepicker__day {
                  width: 2.6rem;
                  line-height: 2.3rem;
                  height: 2.6rem;
                  border-radius: 50%;
                  padding: 2px 3px 2px 3px;
                  margin: 3px;
                }
                .react-datepicker__day--selected {
                  background-color: $primary-color;
                }
              }
            }
            .react-datepicker__header {
              .react-datepicker__day-names {
                margin-top: 7px;
                .react-datepicker__day-name {
                  margin: 0.616rem;
                }
              }
            }
          }
        }
      }
    }
    .history-collapse-wrap {
      position: relative;
      .react-datepicker-wrapper {
        top: 0;
      }
    }
    .date-ytr {
      border-left: 1px solid #ced4da !important;
    }
    .tc-detail-initial-reading {
      .react-datepicker__tab-loop {
        .react-datepicker-popper {
          .react-datepicker {
            .react-datepicker__navigation {
              top: 16px;
            }
            .react-datepicker__month {
              .react-datepicker__month-wrapper {
                .react-datepicker__month-text {
                  width: 5.15rem;
                  padding: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
  //  login css start
  .login-form-detail {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .welcome-title {
      h4 {
        font-size: 30px;
      }
    }
    .forms-input {
      position: relative;
      .fa {
        position: absolute;
        bottom: 15px;
        font-size: 22px;
        left: 18px;
      }
      input {
        padding: 0.775rem 2.9rem;
        box-shadow: none;
      }
    }
    .remember-forgotten {
      display: flex;
      color: #818a91;
      .forgot {
        a {
          text-decoration: none;
          color: #818a91;
        }
      }
    }
    .btn {
      width: 100%;
      padding: 0.8rem 0.75rem;
      background-color: #c0c1c2;
      border-color: #c0c1c2;
      box-shadow: none;
    }
    .btn:hover {
      background-color: #c0c1c2;
      border-color: #c0c1c2;
    }
    form {
      width: 60%;
      margin: 0 auto;
    }
  }
  .login-left-image {
 width: 75%;
  height: 100vh;
  background: url("../img/login.png") no-repeat center center/cover;
  
  // @media (max-width: 1100px) {
  //   display: none;
  // }
  }
}


.important {
  display: block !important;
}

.small-sidebar {
  display: none;
}


.feeder-management-content {
  @include important-text;
  margin-left: 0;
  .comman-head {
    display: flex;
    .management-title {
      flex: 1;
      h5 {
        margin-bottom: 0;
        line-height: 1.8;
      }
    }
    .add-new-substation {
      text-align: -webkit-right;
      .btn {
        display: flex;
        background-color: $primary-color;
        border-color: $primary-color;
        box-shadow: none;
        img {
          line-height: 1.5;
          margin-right: 15px;
        }
        p {
          font-size: 14px;
          line-height: 1.7;
        }
      }
    }
  }
  .horizontal-line {
    background-color: #707070;
    height: 1px;
    opacity: 0.2;
    margin: 1.3rem 0;
    margin-bottom: 7px;
  }
  .code-section {
    display: flex;
    align-items:center;
    // margin-top: 28px;
    .vertical-line {
      width: 2px;
      opacity: 1;
      height: 29px;
      background-color: #dcdde0;
    }

    span {
      font-size: 20px;
      font-family: 'HelveticaNeue-Light';
      color: $base-color;

      b {
        font-family: 'HelveticaNeue-Medium';
      }
    }
  }
  .feeder-management-list {
    background-color: #fff;
    padding-bottom: 15px;
    .search-heading {
      border-bottom: 2px solid #eee;
      margin-bottom: 15px;

      .search-space {
        .form-control {
          box-shadow: none;
          margin-right: 112px;
          background: url(/assets/img/search-tc-icon.svg) no-repeat right 8px center;
          padding-right: 45px;
        }
      }
      .download-upload {
        display: flex;
        justify-content: flex-end;
        .upload-btn {
          position: absolute;
          display: flex;
          color: $base-color;
        }
        .download-btn {
          display: flex;
          background-color: #ececed;
          border-color: #ececed;
          color: $base-color;
        }
      }
    }
    .substation-list {
      border: 1px solid #dddddd;
      border-bottom: unset;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      margin: 0 15px 10px;
      .collapse-detail {
        .card {
          .table {
            tbody {
              tr {
                .action {
                  a {
                    text-decoration: none;
                    color: #373a3c;
                  }
                }
              }
            }
          }
        }
      }
      .substation-collapse {
        border-bottom: 1px solid #d0d0d0;
        position: relative;
        .collapse-text {
          color: $base-color;
          text-decoration: none;
          display: flex;
          font-size: 17px;
          font-weight: 500;
          .fa-angle-down {
            display: none;
            font-size: 22px;
            line-height: 1.2;
          }

          .fa-angle-up {
            display: none;
            font-size: 22px;
            line-height: 1.2;
          }
          .add-feeder {
            display: none;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            .btn {
              display: flex;
              background-color: #3c68c3;
              border-color: #3c68c3;
              box-shadow: none;
              img {
                line-height: 1.5;
                margin-right: 12px;
              }
            }
          }
          .second-sub-title {
            flex: 0.7;
          }
          .first-sub-title {
            flex: 0.44;
          }
        }
        .collapse-text[aria-expanded='false'] {
          .fa-angle-down {
            display: block !important;
          }
        }
        .collapse-text[aria-expanded='true'] {
          .fa-angle-up {
            display: block !important;
          }
        }
        .collapse-text[aria-expanded='true'] {
          .add-feeder {
            display: block !important;
          }
        }
        .collapse-text[aria-expanded='true'] {
          .sub-station-title {
            color: $primary-color;
          }
        }
      }
    }
  }
  nav {
    float: right;
    .pagination {
      .page-link {
        color: #212529;
      }
      .page-link.active {
        color: $primary-color;
      }
      .next-item {
        .page-link {
          background-color: unset;
        }
      }
      .previous-item {
        display: flex;
        span {
          line-height: 2.4;
          font-size: 15px;
        }
        .page-link {
          background-color: unset;
        }
      }
    }
  }
}
.tc-audit-file-management-content {
  @include important-text;
  margin-left: 0;
  .comman-head {
    display: flex;
    .management-title {
      flex: 1;
      h5 {
        margin-bottom: 0;
        line-height: 1.8;
      }
    }
  }

  .tc-audit-file-management-list {
    .tc-audit-file-list {
      box-shadow: $white-box-shadow;
      -moz-box-shadow: $white-box-shadow;
      -webkit-box-shadow: $white-box-shadow;
      border-radius: $border-radius;
      -moz-border-radius: $border-radius;
      -webkit-border-radius: $border-radius;
      .tc-audit-file-active {
        background-color: rgba(241, 242, 245, 0.5) !important;
      }
      .collapse-detail {
        border-top: 1px solid #dddddd;
        background-color: #fff;
        border-bottom-left-radius: 0.55rem;
        border-bottom-right-radius: 0.55rem;
        .card {
          padding: 19px;
          .card-body {
            .tc-top-detail {
              display: flex;
            }

            p {
              font-size: 14px;
            }

            .download-link {
              position: relative;
              top: 3px;
            }

            border: 1px solid #dddddd;
            border-radius: 0.35rem;
            .tc-upload-btn {
              text-align: right;
              button {
                background-color: #c0c1c2;
                border-color: #c0c1c2;
                padding-right: 20px;
                padding-left: 20px;
              }
              input {
                position: absolute;
                opacity: 0;
              }
              .upload-load-active {
                background-color: #3c68c3;
                border-color: #3c68c3;
              }
              .fa {
                line-height: 1.5;
              }
            }
            .tc-details-left {
              .tc-title {
                font-size: 16px;
                font-family: 'HelveticaNeue-Medium';
              }
              span {
                display: flex;
                a {
                  text-decoration: none;
                  display: flex;
                  .fa {
                    line-height: 1.3;
                    font-size: 17px;
                    color: #212529;
                  }
                  p {
                    text-decoration: underline;
                    color: $primary-color;
                  }
                }
              }
            }
            .tc-progress-bar {
              list-style: none;
              display: flex;
              padding-left: 0;
              justify-content: center;
              li {
                span {
                  text-align: center;
                  p {
                    border: 1px solid #dddddd;
                    height: 28px;
                    width: 28px;
                    border-radius: 50%;
                    margin: 0 auto;
                    padding: 1px;
                    color: #dddddd;
                  }
                  .check-icon {
                    background-color: $primary-color;
                    border-color: $primary-color !important;
                    .fa {
                      color: #fff;
                    }
                  }
                  .cross-icon {
                    border-color: red !important;
                    .fa {
                      color: red;
                    }
                  }
                }
              }
              hr {
                width: 25%;
                margin: 0.8rem -1.2rem 1rem !important;
                height: 2px;
                opacity: 1;
                background-color: #dddddd;
              }
            }
            .view-success-screen {
              text-align: right;
              position: relative;
              top: -42px;
              a {
                color: #eb1db4;
                font-size: 15px;
              }
            }
            .result-title {
              font-size: 16px;
              font-family: 'HelveticaNeue-Medium';
              margin-top: 20px;
              margin-bottom: 5px;
            }
            .success-title {
              .fa {
                font-size: 20px;
                color: #378107;
                margin-right: 6px;
              }
            }
            .error-title {
              .fa {
                font-size: 20px;
                color: #eb4800;
                margin-right: 6px;
              }
            }
            .error-msg {
              background-color: #ffefe8;
              color: #eb4800;
              padding: 10px;
              margin-bottom: 10px;
              border-radius: 4px;
              -moz-border-radius: 4px;
              -webkit-border-radius: 4px;
              .fa {
                font-size: 20px;
              }
            }
            .success-msg {
              background-color: #eaf8e1;
              color: #378107;
              padding: 8px;
              border-radius: 0.2rem;
              .fa {
                font-size: 20px;
              }
            }
          }
          .start-audit-btn {
            .btn {
              background-color: #c0c1c2;
              border-color: #c0c1c2;
              box-shadow: none;
              padding: 0.375rem 0.98rem !important;
            }
          }
        }
        .audit-result-section {
          .nav-tabs {
            border-bottom: unset;
            .nav-item {
              .nav-link.active {
                background-color: $primary-color;
                border: 1px solid $primary-color;
                color: #fff;
              }
              .nav-link {
                padding: 0.77rem 2.999rem;
                border-top-left-radius: 0.55rem;
                border-top-right-radius: 0.55rem;
                outline: none;
                box-shadow: none;
                display: flex;
                background-color: #fff;
                border-color: #dee2e6 #dee2e6 #fff;
                color: #212529;
                border-bottom: unset;
                p {
                  line-height: 1.8;
                }
                hr {
                  opacity: 1;
                  width: 1px;
                  margin: 4px 10px 0px 8px;
                  height: 24px;
                  color: #dee2e6;
                }
              }
            }
          }
          .tab-content {
            .tab-pane {
              border: 1px solid #dddddd;
              background-color: #f8f8fa80;
              border-top-right-radius: 0.55rem;
              border-bottom-right-radius: 0.55rem;
              border-bottom-left-radius: 0.55rem;
            }
            .first-toggle-tab {
              .top-transformer-tab {
                .card {
                  border: unset;
                  background-color: unset;
                }

                .main-tc-table {
                  background-color: #fff;
                  padding: 1rem 0rem 0rem 0rem;
                  border-top-right-radius: 0.55rem;
                  .search-heading {
                    display: flex;
                    .search-space {
                      input {
                        box-shadow: none;
                        padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                        margin-right: 89px;
                      }
                      .fa {
                        position: absolute;
                        top: 9px;
                        right: 11px;
                      }
                    }
                  }
                  .download-result {
                    .download-bt {
                      display: inline-flex;
                      line-height: 1.5;
                      color: $base-color;
                      text-decoration: none;
                      cursor: pointer;
                      vertical-align: middle;
                    }
                    .btn {
                      display: inline-flex;
                      background-color: #ececed;
                      border-color: #ececed;
                      color: $base-color;
                    }
                  }
                  .table {
                    border-top: 1px solid #dddddd;
                    thead {
                      tr {
                        th {
                          border-bottom: 1px solid #dddddd;
                        }
                      }
                    }
                    tbody {
                      tr {
                        td {
                          a {
                            .fa {
                              color: rgba(33, 37, 41, 1);
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .pagination-1 {
                  width: 100%;
                  background: #fff;
                  border-bottom-left-radius: 0.55rem;
                  border-bottom-right-radius: 0.55rem;
                  .pagination {
                    justify-content: flex-end;
                    .page-item {
                      .page-link {
                        background-color: unset;
                        color: #212529;
                      }
                    }
                    .previous-item {
                      display: flex;
                      span {
                        line-height: 2.4;
                        font-size: 15px;
                      }
                      .active {
                        color: #3c68c3;
                      }
                    }
                  }
                }
              }
            }
            .second-toggle-tab {
              .top-transformer-tab {
                .card {
                  border: unset;
                  background-color: unset;
                  padding: 19px 19px 1px 19px;
                  .card-body {
                    display: flex;
                    padding: 1rem 1.7rem;
                    background-color: #fff;
                    p {
                      line-height: 1.7;
                      font-size: 16px;
                    }
                    hr {
                      opacity: 1;
                      width: 1px;
                      margin: 4px 19px 0px 0px;
                      height: 24px;
                      color: #dee2e6;
                    }
                  }
                }
                .main-tc-table {
                  background-color: #fff;
                  padding: 1rem 0rem 0rem 0rem;
                  border-top: 1px solid #dddddd;
                  border-top-right-radius: 0.55rem;
                  .search-heading {
                    display: flex;
                    .search-space {
                      .form-control {
                        box-shadow: none;
                        margin-right: 89px;
                      }
                      .fa {
                        position: absolute;
                        top: 9px;
                        right: 11px;
                      }
                    }
                    .download-result {
                      .download-bt {
                        display: inline-flex;
                        line-height: 1.5;
                        color: $base-color;
                        text-decoration: none;
                        cursor: pointer;
                        vertical-align: middle;
                      }
                      .btn {
                        display: inline-flex;
                        background-color: #ececed;
                        border-color: #ececed;
                        color: $base-color;
                      }
                    }
                  }
                  .table {
                    border-top: 1px solid #dddddd;
                    thead {
                      tr {
                        th {
                          border-bottom: 1px solid #dddddd;
                        }
                      }
                    }
                    tbody {
                      tr {
                        td {
                          a {
                            .fa {
                              color: rgba(33, 37, 41, 1);
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .pagination-1 {
                  width: 100%;
                  background: #fff;
                  border-bottom-right-radius: 0.55rem;
                  border-bottom-left-radius: 0.55rem;
                  .pagination {
                    justify-content: flex-end;
                    .page-item {
                      .page-link {
                        background-color: unset;
                        color: #212529;
                      }
                    }
                    .previous-item {
                      display: flex;
                      span {
                        line-height: 2.4;
                        font-size: 15px;
                      }
                      .active {
                        color: #3c68c3;
                      }
                    }
                  }
                }
              }
            }
            .third-toggle-tab {
              .top-transformer-tab {
                .card {
                  border: unset;
                  background-color: unset;
                  padding: 19px 19px 1px 19px;
                  .card-body {
                    display: flex;
                    padding: 1rem 1.7rem;
                    background-color: #fff;
                    p {
                      line-height: 1.7;
                    }
                    hr {
                      opacity: 1;
                      width: 1px;
                      margin: 4px 19px 0px 0px;
                      height: 24px;
                      color: #dee2e6;
                    }
                  }
                }
                .main-tc-table {
                  background-color: #fff;
                  padding: 1rem 0rem 0rem 0rem;
                  border-top: 1px solid #dddddd;
                  border-top-right-radius: 0.55rem;
                  .search-heading {
                    display: flex;
                    .search-space {
                      .form-control {
                        box-shadow: none;
                        margin-right: 89px;
                      }
                      .fa {
                        position: absolute;
                        top: 9px;
                        right: 11px;
                      }
                    }
                    .download-result {
                      .download-bt {
                        display: inline-flex;
                        line-height: 1.5;
                        color: $base-color;
                        text-decoration: none;
                        cursor: pointer;
                        vertical-align: middle;
                      }
                      .btn {
                        display: inline-flex;
                        background-color: #ececed;
                        border-color: #ececed;
                        color: $base-color;
                      }
                    }
                  }
                  .table {
                    border-top: 1px solid #dddddd;
                    thead {
                      tr {
                        th {
                          border-bottom: 1px solid #dddddd;
                        }
                      }
                    }
                    tbody {
                      tr {
                        td {
                          a {
                            .fa {
                              color: rgba(33, 37, 41, 1);
                            }
                          }
                          .audit {
                            text-decoration: none;
                            background: $primary-color;
                            padding: 2px 10px 3px 10px;
                            border-radius: 0.2rem;
                            color: #fff;
                            font-size: 14px;
                          }
                        }
                      }
                    }
                  }
                }
                .pagination-1 {
                  width: 100%;
                  background: #fff;
                  border-bottom-left-radius: 0.55rem;
                  border-bottom-right-radius: 0.55rem;
                  .pagination {
                    justify-content: flex-end;
                    .page-item {
                      .page-link {
                        background-color: unset;
                        color: #212529;
                      }
                    }
                    .previous-item {
                      display: flex;
                      span {
                        line-height: 2.4;
                        font-size: 15px;
                      }
                      .active {
                        color: #3c68c3;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .substation-collapse {
        background-color: #fff;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        padding: 12px 20px;
        .collapse-text {
          color: $base-color;
          text-decoration: none;
          display: flex;
          font-size: 17px;
          font-weight: 500;
          .fa-angle-down {
            display: none;
            font-size: 22px;
            line-height: 1.2;
          }
          .fa-angle-up {
            // display: none;
            font-size: 22px;
            line-height: 1.2;
          }
          .tc-audit-collpase-title {
            display: flex;
            margin-right: auto;
            .sub-station-title {
              line-height: 2.1;
              margin-right: 18px !important;
              font-family: 'HelveticaNeue-Medium';
            }
            input {
              display: block;
              padding: 0.335rem 0.75rem;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              color: #212529;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              border-radius: 0.18rem;
              transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
            .react-datepicker__tab-loop {
              .react-datepicker-popper {
                .react-datepicker {
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
                  border: unset;
                  .react-datepicker__navigation {
                    top: 22px;
                  }
                  .react-datepicker__month-container {
                    .react-datepicker__header {
                      background-color: unset;
                      border-bottom: unset;
                      font-size: 18px;
                      padding-bottom: 8px;
                      line-height: 2.3;
                    }
                    .react-datepicker__month {
                      .react-datepicker__month-wrapper {
                        .react-datepicker__month-text {
                          font-size: 16px;
                          border-radius: 18px;
                          padding: 7px 10px 7px 10px;
                          width: 6.2rem;
                          margin: 3px;
                          font-family: 'Roboto', sans-serif;
                          font-weight: 500;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .collapse-text[aria-expanded='false'] {
          .fa-angle-down {
            display: block !important;
          }
        }
        .collapse-text[aria-expanded='true'] {
          .fa-angle-up {
            display: block !important;
          }
        }
        .collapse-text[aria-expanded='true'] {
          .add-feeder {
            display: block !important;
          }
        }
      }
    }
  }
}
.filter-bar {
  height: 100%;
  width: 475px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0px;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 9999;
  .header {
    background: $base-color;
    padding: 21px;
    color: #fff;
    ul {
      display: flex;
      list-style: none;
      li {
        .fa {
          cursor: pointer;
        }
      }
    }
  }
}

.tariff-tab .tabs a {
  background: #ececed;
  padding: 0.6rem 2.8rem 0.6rem 2.8rem;
  border-radius: 3px;
  text-decoration: none;
  color: #212529;
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 1.4;
  margin-bottom: 8px;
}
.tariff-tab .tabs a:last-child {
  margin-right: 0px !important;
  padding: 0.6rem 3.2rem 0.6rem 3.2rem;
}
.tariff-tab .tabs {
  line-height: 1.6;
}
.tariff-tab {
  padding: 16px;
  border-bottom: 1px solid #ececed;
}
.month-tab .tabs a {
  background: #ececed;
  padding: 0.6rem 1.3rem 0.6rem 1.3rem;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  text-decoration: none;
  color: #29304d;
  margin-right: 7px;
  overflow: hidden;
  // max-width: 140px;
  // width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  // -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.month-tab .tabs {
  line-height: 1.3;
  display: flex;
  flex-wrap: wrap;
}
.month-tab {
  padding: 16px;
  border-bottom: 1px solid #ececed;
}
.remark-tab .tabs a {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  text-decoration: none;
  border: 1px solid $base-color;
  margin-right: 12px;
  margin-bottom: 10px;
  // width: 100%;
  // max-width: 140px;
  text-align: center;
  color: #212529;
  padding: 8px 20px 8px 20px;
  border-radius: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 1.4;
  font-size: 15px;
}
.remark-tab .tabs {
  line-height: 2.1;
}
.remark-tab {
  padding: 16px;
}
.main-reset-apply-section {
  position: absolute;
  bottom: 10px;
  padding-top: 10px;
  width: 100%;
  border-top: 1px solid #ececed;
  .reset-apply-btn.text-right {
    text-align: right;
  }
  .reset-apply-btn {
    width: 100%;
    .reset-btn {
      border: 1px solid #dddddd;
      background: unset;
      padding: 6px 28px 6px 28px;
      margin-right: 10px;
      border-radius: 4px;
    }
    .apply-btn {
      border: 1px solid #dddddd;
      background: #c0c1c2;
      padding: 6px 28px 6px 28px;
      color: #fff;
      border-radius: 4px;
    }
    .apply-btn-active {
      background: #3e63b0 !important;
    }
  }
}
.overlay-filter {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
.remark-active {
  background-color: $primary-color;
}
.tariff-active {
  background-color: #3e63b0 !important;
  color: #fff !important;
}
.month-active {
  background-color: #3e63b0 !important;
  color: #fff !important;
}
.custom-final-reading-divs {
  .final-reading-title {
    background-color: #f8f8fa !important;
  }
  .final-reading-form {
    background-color: #fff;
  }
  .month {
    .react-datepicker-wrapper input {
      background-image: url(../img/yeardownarrow@2x.png);
      background-position: right 9px;
      background-size: 25px auto;
    }

    .react-datepicker__navigation {
      border-width: 0.325rem;
    }
  }
}
.react-datepicker__tab-loop {
  .react-datepicker-popper {
    .react-datepicker {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
      border: unset;
      .react-datepicker__navigation {
        top: 22px;
      }
      .react-datepicker__triangle {
        display: none;
      }
      .react-datepicker__month-container {
        .react-datepicker__header {
          background-color: unset;
          border-bottom: unset;
          font-size: 18px;
        }
        .react-datepicker__month {
          .react-datepicker__month-wrapper {
            .react-datepicker__month-text {
              font-size: 0.9rem;
              border-radius: 18px;
            }
            .react-datepicker__month-text--keyboard-selected {
              background-color: $primary-color;
            }
          }
        }
      }
      .react-datepicker__year--container {
        .react-datepicker__header {
          background-color: unset;
          border-bottom: unset;
          font-size: 18px;
        }
        .react-datepicker__year {
          .react-datepicker__year-wrapper {
            justify-content: center;
            .react-datepicker__year-text {
              font-size: 15px;
              border-radius: 18px;
              font-family: 'Roboto', sans-serif;
            }
            .react-datepicker__month-text--keyboard-selected {
              background-color: $primary-color !important;
            }
            .react-datepicker__year-text--keyboard-selected {
              background-color: $primary-color !important;
            }
          }
        }
      }
    }
  }
}
.main-reading-custom-title {
  .react-datepicker {
    .react-datepicker__navigation {
      top: 22px;
    }
    .react-datepicker__month-container {
      .react-datepicker__month {
        .react-datepicker__month-wrapper {
          .react-datepicker__month-text {
            font-size: 16px;
            border-radius: 18px;
            padding: 7px 7px 7px 7px;
            width: 5.3rem;
            margin: 3px;
          }
        }
      }
    }
  }
}
.history-collapse .react-datepicker-wrapper {
  position: absolute;
  right: 57px;
  top: 14px;
}
.feeder-name-select
  .css-2b097c-container
  .css-yk16xz-control
  .css-1hb7zxy-IndicatorsContainer
  .css-1okebmr-indicatorSeparator {
  display: none;
}
.form-substation-shadow
  .search-feeder
  .css-2b097c-container
  .css-yk16xz-control
  .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.form-substation-shadow
  .search-feeder
  .css-2b097c-container
  .css-1pahdxg-control
  .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.form-substation-shadow .search-feeder .css-2b097c-container .css-1pahdxg-control {
  border-color: hsl(0, 0%, 80%);
  box-shadow: none;
}
.main-feeder-div
  .feeder-form
  .search-feeder
  .css-2b097c-container
  .css-yk16xz-control
  .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.main-feeder-div
  .feeder-form
  .search-feeder
  .css-2b097c-container
  .css-1pahdxg-control
  .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.main-feeder-div .feeder-form .search-feeder .css-2b097c-container .css-1pahdxg-control {
  border-color: hsl(0, 0%, 80%);
  box-shadow: none;
}
.top-feeder-search img {
  position: absolute;
  right: 13px;
  top: 8px;
}
.top-feeder-search
  .css-2b097c-container
  .css-yk16xz-control
  .css-1hb7zxy-IndicatorsContainer
  .css-1okebmr-indicatorSeparator {
  display: none;
}
.top-feeder-search .css-2b097c-container .css-yk16xz-control .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.top-feeder-search .css-2b097c-container .css-1pahdxg-control .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.top-feeder-search .css-2b097c-container .css-1pahdxg-control {
  border-color: hsl(0, 0%, 80%);
  box-shadow: none;
}
.ghost-btn {
  background-color: transparent !important;
  color: #3c68c3;
  max-width: inherit;
  width: auto;
  outline: 0;
  box-shadow: inherit;
}
.ghost-btn:hover {
  color: #3c68c3;
}
.search-feeder {
  .css-2b097c-container {
    .css-yk16xz-control {
      .css-1wy0on6 {
        display: none !important;
      }
    }
  }
}
.search-feeder {
  .css-2b097c-container {
    .css-1pahdxg-control {
      .css-1wy0on6 {
        display: none !important;
      }
    }
    .css-g1d714-ValueContainer {
      cursor: text;
    }
  }
}
.profile-active-btn {
  background-color: #3c68c3;
  padding: 0.435rem 2.1rem;
  border-color: #0d6efd;
}

.form-select-lg option:checked {
  // size: 50%;
  font-size: 90%; /* You can adjust the percentage as needed */
}
