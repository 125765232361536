@import '../../../../assets/scss/variable.scss';

.btn-container{
    display: flex;
    justify-content: center;
}

.heading{
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: $grey-4;

}

.refresh-btn{
    background-color: $white;
    color: $primary-color;
    border-radius: 18px;
    border: 1.46px solid $primary-color;
    font-size: 14px;
    text-align: center;
    box-shadow: 0.98px 0.98px 16px 0px rgba(0,0,0,0.2);
    i{
      margin-left: 10px;
      color: $primary-color;
      font-size: 14px;
      font-weight: 500;
        
    }
}

.refresh-btn:hover{
    background-color: $white !important;
    text-decoration: none;
    color: $primary-color;
    box-shadow: 5px 5px 16px 1px rgba(0,0,0,0.3);
    border-color: $primary-color;

}

.upload-history-btn{
    padding: 15px;
    color: #1c1c1c; 
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    width: 50%;
    max-width: 350px;
    box-shadow: 1px 1px 16.5px 0px rgba(0,0,0, 0.1);
    border: 1px solid $grey-2;

    img{
        margin-left: 10px;
        height: 20px;
        width: 20px;
    }

}

.upload-history-btn:hover{
    border: 1px solid $grey-2;
}