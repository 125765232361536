@import '../../../assets/scss/variable.scss';

.custom-dialog {
  max-width: 60vw;
  margin: 5vh auto;
}

@media screen and (min-width: 768px) {
  //default is 768px
  .custom-dialog {
    width: 35vw; /* Set the width for larger screens */
  }
}
////////////////Footer container//////////////////
.hierarchy-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/////////////////////Footer buttons Style////////////////////
.btn-submit {
  width: 40%;
  background: $primary-color;
  color: #fff;
}
.btn-submit:hover {
  box-shadow: 0 0.5em 0.5em -0.4em $primary-color;
  color: #fff;
}

.btn-cancel {
  width: 40%;
  border-color: $primary-color;
  color: $primary-color;
}
.btn-cancel:hover {
  color: $primary-color;
  box-shadow: 0 0.5em 0.5em -0.4em $primary-color;
}

.download-icon{
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
    filter: brightness(0) saturate(100%) invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);

    }