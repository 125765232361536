@import '../../../assets/scss/variable.scss';

.auditMonthHeading{
    z-index: 1;
    margin:25px auto 20px auto;
    padding:10px 50px 10px 50px;
    color: $secondary-color;
    position: sticky;
    // width: max-content;
    top: 0px;
    background-color: $main-container-bg;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;

    .text{
        font: 400 16px 'Roboto', sans-serif;
        margin:0px 25px
    }

    .hr{
        border:1px solid $grey-4;
        display: flex;
        flex: 1;

    }
}