@import '../../../assets/scss/variable.scss';

header.site-header {
  .navbar-brand {
    margin-right: 30px;
  }

  .navbar-nav li.nav-item {
    .audit-text {
      border-top-left-radius: 4px;
      -moz-border-top-left-radius: 4px;
      -webkit-border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      -moz-border-bottom-left-radius: 4px;
      -webkit-border-bottom-left-radius: 4px;
    }

    .react-datepicker__input-container input {
      max-width: 157px;
    }

    .dropdown-toggle {
      position: relative;
    }
  }
} 
 
 .main-navbar {
      padding-top: 0;
      padding-bottom: 0;
      background-color: #fff;
      box-shadow: 0px 3px 6px #00000014;
      .container-fluid {
        padding-left: 0;
        padding-right: 0;
        .custom-collapse {
          display: flex;
          .navbar-nav {
            .vertical-line {
              width: 1px;
              opacity: 1;
              height: 39px;
              background-color: #edf0f2;
              margin: 6px 7px 0px 18px;
            }
            .react-datepicker__tab-loop {
              .react-datepicker-popper {
                .react-datepicker {
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
                  border: unset;
                  .react-datepicker__navigation {
                    top: 22px;
                  }
                  .react-datepicker__triangle {
                    display: none;
                  }
                  .react-datepicker__month-container {
                    .react-datepicker__header {
                      background-color: unset;
                      border-bottom: unset;
                      font-size: 18px;
                      padding-bottom: 8px;
                      line-height: 2.3;
                    }
                    .react-datepicker__month {
                      .react-datepicker__month-wrapper {
                        .react-datepicker__month-text {
                          font-size: 16px;
                          border-radius: 18px;
                          padding: 7px 10px 7px 10px;
                          width: 6.2rem;
                          margin: 3px;
                          font-family: 'Roboto', sans-serif;
                          font-weight: 500;
                        }
                        .react-datepicker__month-text--keyboard-selected {
                          background-color: $primary-color;
                        }
                      }
                    }
                  }
                  .react-datepicker__year--container {
                    .react-datepicker__header {
                      background-color: unset;
                      border-bottom: unset;
                      font-size: 18px;
                    }
                    .react-datepicker__year {
                      .react-datepicker__year-wrapper {
                        justify-content: center;
                        .react-datepicker__year-text {
                          font-size: 15px;
                          border-radius: 18px;
                          font-family: 'Roboto', sans-serif;
                        }
                        .react-datepicker__year-text--keyboard-selected {
                          background-color: $primary-color !important;
                        }
                        .react-datepicker__month-text--keyboard-selected {
                          background-color: $primary-color !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #navbarTogglerDemo01 {
          .navbar-brand {
            background-color: $third-color;
            // padding-top: 0.8rem;
            // padding-bottom: 0.8rem;
            // padding-left: 1rem;
            // padding-right: 1rem;
            h4 {
              margin-bottom: 0;
            }
          }
          .navbar-nav {
            .user-profile-icon {
              .nav-link {
                display: flex;
                color: #626467;
                line-height: 0.8;
                padding-top: 3px;
                padding-right: 30px;
                span {
                  line-height: 1.2;
                  font-family: 'Roboto', sans-serif;
                  b {
                    color: #242424;
                    margin-bottom: 2px;
                    display: block;
                  }
                }
              }
              .nav-link::after {
                margin-left: 1.255em;
                margin-top: 1rem;
                content: '';
                display: block;
                position: absolute;
                background: url(/assets/img/main-tab-arrow.svg) no-repeat right center;
                background-size: 20px auto;
                width: 24px;
                height: 24px;
                border: none;
                right: 0;
                top: -8px;
                transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
              }
              .dropdown-menu {
                right: 0px;
                border: none;
                box-shadow: 0px 0px 6px #00000029;
                li {
                  .dropdown-item {
                    display: flex;
                    padding: 0.25rem 0.7rem;
                    img {
                      margin-right: 15px;
                    }
                  }
                }
              }
            }
            .nav-item {
              .audit-text {
                background-color: #e2e2e2;
                padding: 12px 22px 10px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                color: #626467;
                font-size: 15px;
              }
              .react-datepicker-wrapper {
                margin-top: 4px;
                input {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                }
              }
            }
          }
        }
      }
    }

.slide-left {
  padding: 0;
  border: 0;
  background-color: unset;
  margin-top: 5px;
  outline: none !important;
  box-shadow: none;
  .fa-bars {
    font-size: 25px;
    color: #626467;
    outline: none;
  }
}

.small-logo {
  display: none;
}

.import {
  display: none;
}

.important {
  display: block !important;
}