@import '../../../assets/scss/variable.scss';

/////////////////////////Dropdown styles///////////////////////////

.hierarchy-dropdown-container {
  width: 60%;
  margin: auto;
  width: 75%;
  position: relative;
}

.dropdown-sub-container {
  position: relative;
}

.dropdown-label {
  font-size: 17px;
  font-weight: 400;
}

label.disabled {
  color: $disabled-text;
}

small.disabled {
  font-size: 12px;
  color: $disabled-text;
}

.custom-dropdown {
  border-color: #626467;
  color: #626467;
  border-width: 1px;
}

.form-select:disabled {
  background-color: $disabled-bg;
  opacity: 1;
  cursor: not-allowed;
  color: $disabled-text;
  background-image: none;
  border-color: $disabled-text;
}

.custom-disabled-arrow {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 70%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-dropdown-options {
  font-family: 'Roboto', sans-serif;
  // font-size: 20px;
}
